import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { provideNgxWebstorage, withNgxWebstorageConfig, withLocalStorage, withSessionStorage } from 'ngx-webstorage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ComponentModule } from './component/component.module';
import { ListingComponent } from './pages/listing/listing.component';
import { BusinessComponent } from './pages/business/business.component';
import { RestService } from './services/rest.service';
import { AuthService } from './services/auth.service';
import { CustomstorageService } from './services/customstorage.service';
import { customHttpInterceptorInterceptor } from './interceptor/custom-http-interceptor.interceptor';
import { BusinessMetaResolver } from './resolver/business-meta.resolver';
// import { AccountComponent } from './pages/profile/account/account.component';
// import { AddressBookComponent } from './pages/profile/address-book/address-book.component';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CuisineSearchPipe } from './pipe/cuisine-search.pipe';
import { ErrorComponent } from './pages/error/error.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CheckoutOtpVerifyComponent } from './pages/checkout-otp-verify/checkout-otp-verify.component';
// import { ReportAProblemComponent } from './pages/report-a-problem/report-a-problem.component';
// import { HeaderComponent } from './component/header/header.component';
// import { FooterComponent } from './component/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ListingComponent,
    BusinessComponent,
    CheckoutComponent,
    CheckoutOtpVerifyComponent,
    // AccountComponent,
    // AddressBookComponent,
    CuisineSearchPipe,
    ErrorComponent,
    // ReportAProblemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    RestService,
    AuthService,
    CustomstorageService,
    provideNgxWebstorage(
      withNgxWebstorageConfig({ separator: '|', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: customHttpInterceptorInterceptor,
      multi: true
    },
    BusinessMetaResolver

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
