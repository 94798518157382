import { Component, Input, NgModule } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { CustomstorageService } from "../../services/customstorage.service";
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  @Input('searchshow') homesearchshow: any;
  pinid: any;
  errortext = "";
  homesearch: boolean = <boolean>false;
  header: any;
  path: any = [];
  fulllocation: any;
  constructor(
    private route: ActivatedRoute,
    public storage: CustomstorageService,
    // public showservice: ShowdetailsService,
    location: Location,
    private rts: Router
  ){

    //this.homesearch = this.homesearchshow;
    this.header = "";
    this.header =  this.storage.getSessionstorage('business_details_heading');
    /* this.sessSt.observe('business_details_heading').subscribe(async info => {
      this.header = await info;
    }); */
    //console.log( this.homesearch);
    rts.events.subscribe(val => {
      this.fulllocation = location.path();
      if (this.fulllocation != "") {
        this.path = this.fulllocation.split('/');
        //console.log(this.path);
        var stringPath = "/" + this.path[1] + "/" + this.path[2];
        if (stringPath == "/home/") {
          this.homesearch = true;
        } else {
          this.homesearch = false;
          // this.header = "";
          // this.sessSt.observe('business_details_heading').subscribe(info => {
          //   this.header = info;
            //console.log(this.header);
          // });
        }
      } else {
        this.homesearch = true;
      }
    });
  }
  
  ngOnInit() {
    //console.log(this.header);
    // this.homesearch = true;
    let headval = this.storage.getSessionstorage('business_details_heading');
    if (headval != null) {
      this.header = headval;
    }
  }
  goto(){
    console.log("hello");
    console.log(this.pinid);
    if (this.pinid) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          search_by_postcode: this.pinid
        }
      };
      this.rts.navigate(["listing"], navigationExtras);
    }else {
      // this.errortext="Enter Postcode";
      // $("#search_by_pin").focus();
    }
  }
}
