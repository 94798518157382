import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ListingComponent } from './pages/listing/listing.component';
import { BusinessComponent } from './pages/business/business.component';
import { BusinessMetaResolver } from './resolver/business-meta.resolver';
import { ErrorComponent } from './pages/error/error.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CheckoutOtpVerifyComponent } from './pages/checkout-otp-verify/checkout-otp-verify.component';

const routes: Routes = [
  { path: "home", component: HomeComponent, data: { animation: "home" }},
  { path: "", component: HomeComponent, data: { animation: "home" } },
  { path: "listing", component: ListingComponent, data: { animation: "listing" }},
  // { path: "business/:slug", component: BusinessComponent, data: { animation: "aminate" }, resolve: { message: DashboardResolver, businessMeta: BusinessMetaResolver} },
  { path: "business/:slug", component: BusinessComponent, data: { animation: "business" }, resolve: { businessMeta: BusinessMetaResolver}},
  { path: "checkout", component: CheckoutComponent, data: { animation: "business" }},
  {path: "order/checkout-otp/:okey", component: CheckoutOtpVerifyComponent, data: {animation:"business"}},
  { path: "account", loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule), data: { animation: "account" } },
  { path: "information", loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), data: { animation: "aminate" } },
  { path: 'order-complete', loadChildren: () => import('./pages/sucess/sucess.module').then(m => m.SucessModule) },
  { path: 'booking-complete', loadChildren: () => import('./pages/success-booking/success-booking.module').then(m => m.SuccessBookingModule) },
  { path: "contact-us", loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule), data: { animation: "aminate" } },
  { path: "**", component: ErrorComponent, data: { animation: "home" }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
