import { Component,ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { RestService } from '../../services/rest.service';
import { Router, ActivatedRoute } from "@angular/router";
import { CustomstorageService } from '../../services/customstorage.service';

@Component({
  selector: 'app-checkout-otp-verify',
  templateUrl: './checkout-otp-verify.component.html',
  styleUrl: './checkout-otp-verify.component.scss'
})
export class CheckoutOtpVerifyComponent {
  @ViewChild("changeMobileModal") changeMobileModal: any;
  public base_url = environment.baseUrl;
  public showdloader = false;
  private timerFlag = 0;
  public changeMobileBtnClick = false;
  public mobileNumberChanged = false;
  public timeLeft = 0;
  public apiSuccMsg = '';
  public apiErrMsg = '';
  public timerM:any = '00';
  public timerS:any = '00';
  public mobile = '';
  public otp1 = '';
  public mobile_new = '';
  public mobile_otp = '';
  public okey = '';
  public orderDetails:any = '';
  constructor(
    public modalService: NgbModal,
    public restProvider: RestService,
    public rts: Router,
    public route: ActivatedRoute,
    public storage: CustomstorageService,
  ) {
    route.queryParams.subscribe(params => {
      // console.log(123);
      this.checkCart();
    })
    this.okey = this.route["snapshot"]["params"]["okey"];
  }

  ngOnInit(): void {
    this.get_details();
  }
  async checkCart(){
    let cartitems = await this.storage.getLocalstorage("cartlist");
    if (cartitems == undefined || cartitems == null) {
      window.location.href = this.base_url;
    }
  }
  timer(timerFlag = 1){
    this.timerM = Math.floor(this.timeLeft / 60)<=9?"0"+Math.floor(this.timeLeft / 60):Math.floor(this.timeLeft / 60);
    this.timerS = Math.floor(this.timeLeft % 60)<=9?"0"+Math.floor(this.timeLeft % 60):Math.floor(this.timeLeft % 60);
    let tempLoaclFlag = this.timerFlag;
    setTimeout(()=>{
      this.timeLeft--;
      if(this.timeLeft>=0 && timerFlag == this.timerFlag){
        this.timer(tempLoaclFlag);
      }
    },1000)
  }
  get_details(x='init'){
    let payload = {okey:this.okey}
    this.restProvider
    .postdataapi(payload, "business/cash_order_otp_details")
    .subscribe((result: any) => {

      if (result.responsecode == "200") {
        this.changeMobileBtnClick=false;
        this.mobileNumberChanged=false;
        this.orderDetails=result.order_details;
        this.mobile_new = result.order_details.mobile;
        this.mobile = result.order_details.mobile;
        this.timeLeft = result.otp_expire_in;
        this.timerFlag++;
        this.timer(this.timerFlag);
        if(x=='init' || x=='changeMob'){
          this.apiSuccMsg = 'We have sent the code to your number, please verify.';
        }else if(x=='resendOtp'){
          // this.apiSuccMsg = 'We have sent the code to your number, please verify.';
        }else{
          // this.apiSuccMsg = '';
        }
      }else {
        this.apiErrMsg = result.status
        
      }

      this.apiMsgReset();
      this.showdloader = false;
    })
   /*  .catch((er: any) => {
      //console.log("data fetching failed");
      //console.log(er);
      // this.showdata=true;
      this.showdloader = false;
      // this.loginpopS.opencommonpopup("Error", "Something went wrong , Try again", "normal");
    }); */
  }
  changeMobileBtn(){
    this.changeMobileBtnClick =true;
    let t1 = this.mobile;
    this.mobile_new = t1;
  }
  cancelEditMobile(){
    this.changeMobileBtnClick =false;
  }
  checkMobUpdate(){
    if(this.mobile!=this.mobile_new){
      this.mobileNumberChanged = true;
    }else{
      this.mobileNumberChanged = false;
    }
  }
  resendMobApi(){
    this.changeMobApi(true,'yes');
  }
  changeMobApi(x=false,isResend = 'no'){
    
    if((x && this.mobile != this.mobile_new && isResend == 'no') || (x && this.mobile == this.mobile_new && isResend == 'yes')){
      let payload = {okey:this.okey,mobile:this.mobile_new}
      this.restProvider
      .postdataapi(payload, "business/cash_order_change_mobile")
      .subscribe((result: any) => {
        // console.log(result);
        if (result.responsecode == "200") {
          if(isResend == 'yes'){
            if(this.timeLeft>0){
              this.apiErrMsg = 'Please wait, we have already send a verification code. If you not receved then try after '+this.timerM+":"+this.timerS+" minute";
            }else{
              this.apiSuccMsg = 'We have sent the code to your number, please verify.';
            }
            this.get_details('resendOtp');
          }else{
            this.get_details('changeMob');
          }
        }else {
          //console.log(result.status);
          this.apiErrMsg = result.status;
        }
        this.apiMsgReset();
        this.showdloader = false;
      })
      /* .catch((er: any) => {
        //console.log("data fetching failed");
        //console.log(er);
        // this.showdata=true;
        this.showdloader = false;
        // this.loginpopS.opencommonpopup("Error", "Something went wrong , Try again", "normal");
      }); */
    }else{
      // you have enter same mobile number
      if(!x){
        this.apiErrMsg = 'Please enter valid mobile number.';
      }else{
        this.apiErrMsg = 'New mobile number is same with old mobile number';
      }
      this.apiMsgReset();
    }
  }
  verifyOtp(x = false){
    if(x){
      let payload = {okey:this.okey,otp:this.mobile_otp}
      this.restProvider
      .postdataapi(payload, "business/cash_order_otp_verify")
      .subscribe((result: any) => {
        // console.log(result);
        if (result.responsecode == "200") {
          this.apiSuccMsg = result.status;
          window.location.href = this.base_url + "order-complete?v=" + this.okey;
        }else {
          //console.log(result.status);
          this.apiErrMsg = result.status;
          // wrong otp
        }
        this.apiMsgReset();
        this.showdloader = false;
      })
      /* .catch((er: any) => {
        //console.log("data fetching failed");
        //console.log(er);
        // this.showdata=true;
        this.showdloader = false;
        // this.loginpopS.opencommonpopup("Error", "Something went wrong , Try again", "normal");
      }); */
    }else{
      this.apiErrMsg = 'Please enter verification code.';
      this.apiMsgReset();
    }
  }
  apiMsgReset(){
    setTimeout(()=>{
      this.apiErrMsg = '';
      this.apiSuccMsg = '';
    },5000)
  }
}
