import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { RestService } from "../../services/rest.service";
// import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { CustomstorageService } from "../../services/customstorage.service";
@Component({
  selector: "app-listing",
  templateUrl: "./listing.component.html",
  styleUrls: ["./listing.component.scss"]
})
export class ListingComponent implements OnInit {
  postbody = {
    zipcode: "",
    catagory: "1",
    cusine_id: "0",
    sort_by: "",
    skey: ""
  };
  public list: any = [];
  subcatlist = [];
  showdloader = true;
  cusine = "0";
  searchText = "";
  constructor(
    private route: ActivatedRoute,
    public restProvider: RestService,
    private rts: Router,
    // public localSt: LocalStorageService,
    public storage: CustomstorageService,
  ) {
    this.route.queryParams.subscribe(params => {
      // console.log(params.search_by_postcode);
      this.postbody.zipcode = params["search_by_postcode"];
      this.loaddata();
    });
  }

  print() {
    // console.log(this.cusine);
  }
  openmobilefilter() {
    // document.getElementById("filterpanel").classList.add("showfilter");
  }
  closemobilefilter() {
    // document.getElementById("filterpanel").classList.remove("showfilter");
  }

  loaddata() {
    this.restProvider
      .postdataapi(this.postbody, "search/home")
      .subscribe(async (result: any) => {
        // console.log(result);

        if (result.responsecode == "200") {
          this.storage.setLocalstorage("searched_pincode", this.postbody.zipcode);
          // this.localSt.store("searched_pincode", this.postbody.zipcode);
          this.list = result.list;
          this.subcatlist = result.subcatagory;
          // console.log(this.list);
        } else {
          this.list = [];
          // this.popup.opencommonpopup("Error",result.status,"normal");
          // this.rts.navigate(["home"]);
        }

        this.showdloader = false;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }, (error: any) => {
        // console.log(error);
        // console.log("data fetching failed");
        this.showdloader = false;
      })
  }

  ngOnInit() { }
}
