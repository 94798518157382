import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cuisineSearch'
})
export class CuisineSearchPipe implements PipeTransform {

  transform(items: any[], searchText: string, cusine: string): any {
    if (!items) return [];
    if (!searchText && cusine == "0") return items;
    //if (searchText === undefined && cusine == "0") return items;
    if (searchText !== undefined) {
      searchText = searchText.toLowerCase();
    }

    return items.filter(it => {
      if (cusine == "0" && searchText) {
        return it.business_name.toLowerCase().includes(searchText);
      } else if (cusine != "0" && searchText) {
        return (
          it.business_name.toLowerCase().includes(searchText) &&
          it.subcategory.toLowerCase().includes(cusine)
        );
      } else if (cusine != "0" && !searchText) {
        return it.subcategory.toLowerCase().includes(cusine);
      }
    });
  }

}