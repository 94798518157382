// import { ResolveFn } from '@angular/router';
// import { RestService } from '../services/rest.service';

// export const BusinessMetaResolver: ResolveFn<boolean> = (route, state) => {
//   console.log(route)
//   return true;
// };

import { Injectable } from '@angular/core';
import { RestService } from '../services/rest.service';



import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class BusinessMetaResolver  {
  constructor(private rest: RestService, ) { }
  resolve(route: ActivatedRouteSnapshot) {
    return this.rest.getData({'slug': route["params"]["slug"]},"information/business_meta_details")
  }
}
