export class CartModel {

    public product_id: String;
    public product_name: String;
    public product_price: Number;
    public sell_price: Number;
    public product_qnty: Number;
    public product_type: String;
    public main_pro_id: any;
    public main_pro_name: any;
    public extras: any;
    public has_discount: any;
    public has_discount_pickup: any;
    public has_discount_delivery: any;
    public product_comment: any;
    public menucategory_rank: number;
    constructor(obj: any) {

        this.product_id = obj.product_id;
        this.product_name = obj.product_name;
        this.product_price = Number(obj.product_price);
        this.sell_price = Number(obj.sell_price);
        this.product_qnty = (!obj.product_qnty) ? 1 : obj.product_qnty;
        this.product_type = obj.select_product_type;
        this.main_pro_id = obj.main_pro_id;
        this.main_pro_name = obj.main_pro_name;
        this.extras = obj.extras;
        this.has_discount = 'n';
        this.has_discount_pickup = 'No';
        this.has_discount_delivery = 'No';
        this.product_comment = "";
        if(obj.menucategory_rank != undefined && obj.menucategory_rank != null && Number(obj.menucategory_rank) >0){
            this.menucategory_rank = Number(obj.menucategory_rank);
        }else{
            this.menucategory_rank = 0;
        }
    }

    public setitem(obj: any) {

        this.product_id = obj.product_id;
        this.product_name = obj.product_name;
        this.product_price = Number(obj.product_price);
        this.sell_price = Number(obj.sell_price);
        this.product_qnty = Number(obj.product_qnty);
        this.product_type = obj.select_product_type;
        this.main_pro_id = obj.main_pro_id;
        this.main_pro_name = obj.main_pro_name;
        this.extras = obj.extras;
        this.has_discount = obj.has_discount;
        this.has_discount_pickup = obj.has_discount_pickup;
        this.has_discount_delivery = obj.has_discount_delivery;
        this.product_comment = obj.product_comment;

        if(obj.menucategory_rank != undefined && obj.menucategory_rank != null && Number(obj.menucategory_rank) >0){
            this.menucategory_rank = Number(obj.menucategory_rank);
        }else{
            this.menucategory_rank = 0;
        }
    }


    public copyitem(obj: any) {

        this.product_id = obj.product_id;
        this.product_name = obj.product_name;
        this.product_price = Number(obj.product_price);
        this.sell_price = Number(obj.sell_price);
        this.product_qnty = Number(obj.product_qnty);
        this.product_type = obj.product_type;
        this.main_pro_id = obj.main_pro_id;
        this.main_pro_name = obj.main_pro_name;
        this.extras = obj.extras;
        this.has_discount = obj.has_discount;
        this.has_discount_pickup = obj.has_discount_pickup;
        this.has_discount_delivery = obj.has_discount_delivery;
        this.product_comment = obj.product_comment;

        if(obj.menucategory_rank != undefined && obj.menucategory_rank != null && Number(obj.menucategory_rank) >0){
            this.menucategory_rank = Number(obj.menucategory_rank);
        }else{
            this.menucategory_rank = 0;
        }
    }

    public get_product_rank(){
        return this.menucategory_rank;
    }
    public setquantity(qnty:Number) {
        this.product_qnty = qnty;
    }

    public get_product_qnty() {
        //this.product_qnty = (this.product_qnty !=null)?this.product_qnty:1;
        return this.product_qnty;
    }
    public get_product_price() {
        return this.product_price;
    }

    public get_sell_price() {
        return this.sell_price;
    }

    public addquantity() {
        this.product_qnty = Number(this.product_qnty) + 1;
    }

    public delquantity() {

        if (Number(this.product_qnty) > 1) {
            this.product_qnty = Number(this.product_qnty) - 1;

        }

    }

    public set_discount(disval: any, dd: any, pd: any) {
        this.has_discount = disval;
        this.has_discount_delivery = dd;
        this.has_discount_pickup = pd;

    }

    public get_discount() {
        return this.has_discount;
    }

    public get_main_pro_id() {
        return this.main_pro_id;
    }

    public get_pro_id() {
        return this.product_id;
    }




}