<!-- common part for all pages start -->
<!-- header section start here -->
<app-header />

<!-- header section end here -->
<!-- common part for all pages end -->

<section class="total-listing-section mt-3">

  <div class="container">
    <div class="row">
      <!-- sidebar start here -->
      <div class="col-lg-3 filter-section">
        <div class="location-bar">
          <div class="img-location">
            <img src="../../../assets/images/banner/location.png" alt="">
            <p>B201 AA, Birmingham</p>
          </div>
          <a href="#">change location</a>
        </div>

        <div id="accordion" class="accordion-container">
          <h4 class="accordion-title js-accordion-title">
            <div class="left-panel">
              <svg id="Layer_1" height="20" viewBox="0 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1">
                <path
                  d="m12 88.734h74.372a69.573 69.573 0 0 0 137.066 0h276.562a12 12 0 0 0 0-24h-276.562a69.573 69.573 0 0 0 -137.066 0h-74.372a12 12 0 0 0 0 24zm142.905-57.589a45.59 45.59 0 1 1 -45.59 45.589 45.641 45.641 0 0 1 45.59-45.589zm345.095 212.855h-52.819a69.572 69.572 0 0 0 -137.065 0h-298.116a12 12 0 1 0 0 24h298.116a69.573 69.573 0 0 0 137.066 0h52.818a12 12 0 1 0 0-24zm-121.352 57.589a45.59 45.59 0 1 1 45.59-45.589 45.64 45.64 0 0 1 -45.59 45.589zm121.352 121.677h-276.562a69.573 69.573 0 0 0 -137.066 0h-74.372a12 12 0 0 0 0 24h74.372a69.573 69.573 0 0 0 137.066 0h276.562a12 12 0 0 0 0-24zm-345.095 57.589a45.59 45.59 0 1 1 45.59-45.589 45.641 45.641 0 0 1 -45.59 45.589z" />
              </svg>
              Filters
            </div>
            <div class="right-panel">
              <a href="index.html">Reset</a>
            </div>
          </h4>
          <div class="accordion-content">
            <form>
              <a href="#">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p>Free Delivery</p>
                  </div>
                  <p>77</p>
                </div>
              </a>

              <a href="#">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p>50% OFF</p>
                  </div>
                  <p>10</p>
                </div>
              </a>

              <a href="#">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p>Indian Curry</p>
                  </div>
                  <p>25</p>
                </div>
              </a>

              <a href="#">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p>Pizza</p>
                  </div>
                  <p>50</p>
                </div>
              </a>

              <a href="#">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p>Ice Cream</p>
                  </div>
                  <p>55</p>
                </div>
              </a>

              <a href="#">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p>Desert</p>
                  </div>
                  <p>47</p>
                </div>
              </a>

              <a href="#">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p>American</p>
                  </div>
                  <p>62</p>
                </div>
              </a>

            </form>
          </div>
        </div>
      </div>

      <!-- sidebar end here -->

      <!-- listing start here -->
      <div class="col-lg-9 search-listing">
        <div class="search-bar">
          <div class="form-group">
            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Search By Restaurants" [(ngModel)]="searchText">
              <app-svg-icon iconname="bs-search"></app-svg-icon>
            <a href="#">Search</a>
          </div>

        </div>

        <div class="location-bar2">
          <div class="location-mobile">
            <div class="img-location">
              <img src="../../../assets/images/banner/location.png" alt="">
              <p>B201 AA, Birmingham</p>
            </div>
            <a href="#">change location</a>
          </div>
        </div>

        <div class="location-sort mt-3">
          <div class="loca-part">
            <app-svg-icon iconname="restaurant-open"></app-svg-icon>
            <h6>{{ list.length }} Restaurents Open </h6>

          </div>
          <div class="sort-part">
            <a class="default-btn btn active mr-20 filter-mobile" type="button" data-bs-toggle="modal"
              data-bs-target="#exampleModal">
              <svg id="svg8" height="25" viewBox="0 0 6.3499999 6.3500002" fill="#f03157" width="25"
                xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#"
                xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                xmlns:svg="http://www.w3.org/2000/svg">
                <g id="layer2" transform="translate(0 -2.117)">
                  <path id="path1023"
                    d="m4.2307167 2.1166666c-.4880475 0-.8974542.3392647-1.0162096.792781h-2.94345009a.26423512.2648633 0 0 0 0 .5297266h2.94293449c.118161.4539438.5280618.792781 1.0167252.792781.4886632 0 .9001907-.3388372 1.0187875-.792781h.8306004a.26423512.2648633 0 0 0 0-.5297266h-.831116c-.1191931-.4535163-.5302245-.792781-1.0182719-.792781zm0 .5292097c.294708 0 .5279547.2343181.5279547.5297266 0 .2954087-.2332467.5292098-.5279547.5292098-.2947079 0-.5258924-.2338011-.5258924-.5292098 0-.2954085.2311845-.5297266.5258924-.5297266zm-2.1102718 1.5860789c-.4888354 0-.9003576.3407431-1.0187874.795365h-.83060049a.26400373.26463136 0 0 0 -.0252635 0 .26434585.26497429 0 0 0 .0252635.5292098h.83111609c.1191929.4535163.5302244.792781 1.0182718.792781.4880475 0 .899079-.3392647 1.0182719-.792781h2.9413878a.26404222.26466994 0 1 0 0-.5292098h-2.9408723c-.1184297-.4546219-.5299519-.795365-1.0187874-.795365zm0 .5292098c.2947081 0 .5279546.2343179.5279546.5297265s-.2332465.5292098-.5279546.5292098c-.294708 0-.5279546-.2338012-.5279546-.5292098s.2332466-.5297265.5279546-.5297265zm2.1102718 1.588146c-.4888355 0-.8987305.3407432-1.0167252.795365h-2.94293449a.26400373.26463136 0 0 0 -.0252635 0 .2643459.26497435 0 0 0 .0252635.5292097h2.94345009c.1187554.4535164.5281621.7927812 1.0162096.7927812.4880474 0 .8990788-.3392648 1.0182719-.7927812h.831116a.26404227.26466999 0 0 0 0-.5292097h-.8306004c-.1184297-.4546218-.5299522-.795365-1.0187875-.795365zm0 .5292098c.294708 0 .5279547.234318.5279547.5297265 0 .2954086-.2332467.5292098-.5279547.5292098-.2947079 0-.5258924-.2338012-.5258924-.5292098 0-.2954085.2311845-.5297265.5258924-.5297265z"
                    font-variant-ligatures="normal" font-variant-position="normal" font-variant-caps="normal"
                    font-variant-numeric="normal" font-variant-alternates="normal" font-feature-settings="normal"
                    text-indent="0" text-align="start" text-decoration-line="none" text-decoration-style="solid"
                    text-decoration-color="#000000" text-transform="none" text-orientation="mixed" white-space="normal"
                    shape-padding="0" isolation="auto" mix-blend-mode="normal" solid-color="#000000" solid-opacity="1"
                    vector-effect="none" paint-order="normal" />
                </g>
              </svg>
            </a>
            <!-- <a href="#">
               <h6>Sort By</h6>
              <app-svg-icon iconname="sort-by"></app-svg-icon>
             
            </a> -->

            <label class="dropdown">

              <div class="dd-button">
                Sort By
              </div>
            
              <input type="checkbox" class="dd-input" id="test">
            
              <ul class="dd-menu">
                <li>A To Z</li>
                <li>O To 1</li>
                
                
              </ul>
              
            </label>
          </div>
        </div>

        <div class="card-system">
          <a class="search-list" *ngFor="let item of list | cuisineSearch: searchText:cusine" href="business/{{item.business_slug}}">
            <div class="card mb-3">
              <div class="container"></div>
              <div class="row card-row">
                <div class="col-md-4 img-div">
                  <img src="{{ item.business_logo_thumb }}" class="img-fluid" alt="">
                  <!-- <img src="../../../assets/images/listing/image.png" class="img-fluid rounded-start w-100" alt=""> -->
                  <div class="love">
                    <a href="" tabindex="-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-heart"
                        viewBox="0 0 16 16">
                        <path
                          d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15">
                        </path>
                      </svg>
                    </a>
                  </div>
                </div>
                <div class="col-md-8 body-div">
                  <div class="card-body">
                    <h5 class="card-title">{{item.business_name}}</h5>
                    <p class="card-text">
                      <span><img src="../../../assets/images/banner/location.png" alt=""></span>
                      {{ item.business_address }}
                    </p>
                    <p>{{item.subcategory_name}}</p>
                    <div class="col-deli-star">
                      <div class="star">
                        <p>4.9
                          <app-svg-icon iconname="ratting"></app-svg-icon>
                          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff"
                            class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path
                              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                            </path>
                          </svg> -->
                        </p>
                      </div>
                      <p [class.active]="item.pickup_order_possible !='no'">Collection</p>
                      <span>|</span>
                      <p [class.active]="item.delivery_order_possible!='no'">Delivery</p>
                    </div>
                    <div class="pre-order">Pre-order</div>
                    <div class="order-now">Order-now</div>
                    <div class="percentage-colection" *ngIf="item.default_delivery_discount != null">
                      <p *ngIf="item.default_delivery_discount.discount_text != null">
                        <span>
                          <img src="../../../assets/images/listing/percent.png" alt="">
                          {{item.default_delivery_discount.discount_text}}
                        </span>
                      </p>
                    </div>
                    <div class="percentage-colection" *ngIf="item.default_pickup_discount != null">
                      <p *ngIf="item.default_pickup_discount.discount_text != null">
                        <span>
                          <img src="../../../assets/images/listing/percent.png" alt="">
                          {{item.default_pickup_discount.discount_text}}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
          <!-- <div class="card mb-3">
            <div class="row g-0 card-row">
              <div class="col-md-4 img-div">
                <img src="../../../assets/images/listing/image.png" class="img-fluid rounded-start w-100" alt="">
                <img class="logo-part-sec" src="../../../assets/images/top-recomended/logo.png" alt="">
                <div class="love">
                  <a href="#" tabindex="-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-heart"
                      viewBox="0 0 16 16">
                      <path
                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15">
                      </path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-md-8 body-div">
                <div class="card-body">
                  <h5 class="card-title">Amore Mio</h5>
                  <p class="card-text">
                    <span><img src="../../../assets/images/banner/location.png" alt=""></span>
                    90 harthown road, Birmingham
                  </p>
                  <div class="col-deli-star">
                    <div class="star">
                      <p>4.9
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff"
                          class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                          </path>
                        </svg>
                      </p>
                    </div>
                    <p>Collection</p>
                    <span>|</span>
                    <p>Delivery</p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="row g-0 card-row">
              <div class="col-md-4 img-div">
                <img src="../../../assets/images/listing/image.png" class="img-fluid rounded-start w-100" alt="">
                <img class="logo-part-sec" src="../../../assets/images/top-recomended/logo.png" alt="">
                <div class="love">
                  <a href="#" tabindex="-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-heart"
                      viewBox="0 0 16 16">
                      <path
                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15">
                      </path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-md-8 body-div">
                <div class="card-body">
                  <h5 class="card-title">Amore Mio</h5>
                  <p class="card-text">
                    <span><img src="../../../assets/images/banner/location.png" alt=""></span>
                    90 harthown road, Birmingham
                  </p>
                  <div class="col-deli-star">
                    <div class="star">
                      <p>4.9
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff"
                          class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                          </path>
                        </svg>
                      </p>
                    </div>
                    <p>Collection</p>
                    <span>|</span>
                    <p>Delivery</p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="row g-0 card-row">
              <div class="col-md-4 img-div">
                <img src="../../../assets/images/listing/image.png" class="img-fluid rounded-start w-100" alt="">
                <img class="logo-part-sec" src="../../../assets/images/top-recomended/logo.png" alt="">
                <div class="love">
                  <a href="#" tabindex="-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-heart"
                      viewBox="0 0 16 16">
                      <path
                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15">
                      </path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-md-8 body-div">
                <div class="card-body">
                  <h5 class="card-title">Amore Mio</h5>
                  <p class="card-text">
                    <span><img src="../../../assets/images/banner/location.png" alt=""></span>
                    90 harthown road, Birmingham
                  </p>
                  <div class="col-deli-star">
                    <div class="star">
                      <p>4.9
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff"
                          class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                          </path>
                        </svg>
                      </p>
                    </div>
                    <p>Collection</p>
                    <span>|</span>
                    <p>Delivery</p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="row g-0 card-row">
              <div class="col-md-4 img-div">
                <img src="../../../assets/images/listing/image.png" class="img-fluid rounded-start w-100" alt="">
                <img class="logo-part-sec" src="../../../assets/images/top-recomended/logo.png" alt="">
                <div class="love">
                  <a href="#" tabindex="-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-heart"
                      viewBox="0 0 16 16">
                      <path
                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15">
                      </path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-md-8 body-div">
                <div class="card-body">
                  <h5 class="card-title">Amore Mio</h5>
                  <p class="card-text">
                    <span><img src="../../../assets/images/banner/location.png" alt=""></span>
                    90 harthown road, Birmingham
                  </p>
                  <div class="col-deli-star">
                    <div class="star">
                      <p>4.9
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff"
                          class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                          </path>
                        </svg>
                      </p>
                    </div>
                    <p>Collection</p>
                    <span>|</span>
                    <p>Delivery</p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="row g-0 card-row">
              <div class="col-md-4 img-div">
                <img src="../../../assets/images/listing/image.png" class="img-fluid rounded-start w-100" alt="">
                <img class="logo-part-sec" src="../../../assets/images/top-recomended/logo.png" alt="">
                <div class="love">
                  <a href="#" tabindex="-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-heart"
                      viewBox="0 0 16 16">
                      <path
                        d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15">
                      </path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="col-md-8 body-div">
                <div class="card-body">
                  <h5 class="card-title">Amore Mio</h5>
                  <p class="card-text">
                    <span><img src="../../../assets/images/banner/location.png" alt=""></span>
                    90 harthown road, Birmingham
                  </p>
                  <div class="col-deli-star">
                    <div class="star">
                      <p>4.9
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff"
                          class="bi bi-star-fill" viewBox="0 0 16 16">
                          <path
                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                          </path>
                        </svg>
                      </p>
                    </div>
                    <p>Collection</p>
                    <span>|</span>
                    <p>Delivery</p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>
                  <div class="percentage-colection">
                    <p>
                      <span>
                        <img src="../../../assets/images/listing/percent.png" alt="">
                        25% OFF*
                      </span>
                      on collection order over £30
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div> -->

        </div>
      </div>
      <!-- listing end here -->
    </div>

  </div>

 

</section>


<!-- footer start here -->
<app-footer />
<!-- footer end here -->