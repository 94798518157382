<app-header />


<!-- <section class="checkout-all total-listing-section">

  
  
    <h3>Place Your Order</h3>

    <div class="row checkout-all-row">
      <div class="col-lg-7 left-panel">

        <div class="time-part-selection box">
          <h5>Your Information</h5>
          <form [formGroup]="deliveryAddresForm">
            <div class="form-group">
              <input type="text" class="form-control" id="name" formControlName="name" placeholder="Enter your name">
              <div class="alert alert-danger"
                *ngIf="(addressDelForm['name']?.touched  && addressDelForm['name']?.errors?.['required'])">
                Name is required
              </div>
            </div>
            <div class="form-group">

              <input type="email" class="form-control" id="email" formControlName="email"
                placeholder="Enter your email">
              <div class="alert alert-danger"
                *ngIf="addressDelForm['email'].errors !=null && addressDelForm['email'].errors['required'] && addressDelForm['email'].touched">
                Email address is required
              </div>
              <div class="alert alert-danger"
                *ngIf="addressDelForm['email'].errors !=null && addressDelForm['email'].errors['email'] && addressDelForm['email'].touched">
                Please enter valid email
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail1" formControlName="phNo"
                placeholder="Enter your phone number">
              <div class="alert alert-danger"
                *ngIf="addressDelForm['phNo']?.touched && addressDelForm['phNo']?.errors?.['required']">
                Phone number is required
              </div>
              <div class="alert alert-danger"
                *ngIf="addressDelForm['phNo']?.touched && addressDelForm['phNo']?.errors?.['pattern']">
                Phone number must have 11 digit and a leading 0
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail1" formControlName="address"
                placeholder="Enter your address">
              <div class="alert alert-danger"
                *ngIf="addressDelForm['address']?.touched && addressDelForm['address']?.errors?.['required']">
                Address is required
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail1" formControlName="postCode"
                placeholder="Enter your post code">
              <div class="alert alert-danger"
                *ngIf="addressDelForm['postCode']?.touched && addressDelForm['postCode']?.errors?.['required']">
                Postcode is required
              </div>
            </div>
          </form>

          <div class="address-form-add">
            <form class="address-form">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" for="exampleCheck1">
                  <h5>Home</h5>
                  <p>Jhon Abraham</p>
                  <p>Abrahamtest.com</p>
                  <p>0987654321</p>
                  <p>London</p>
                  <p>2BNG2A</p>
                </label>
                <a class="edit" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </a>
              </div>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" for="exampleCheck1">
                  <h5>Work</h5>
                  <p>Jhon Abraham</p>
                  <p>Abrahamtest.com</p>
                  <p>0987654321</p>
                  <p>London</p>
                  <p>2BNG2A</p>
                </label>
                <a class="edit" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                  </svg>
                </a>
              </div>
            </form>
            <div class="address-div">
              <a class="address" href="#">
                + Address
              </a>
            </div>
          </div>
          <form>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Any Comment !!!</label>
              <p>{{allergynotetxt}}.</p>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
          </form>


          <div class="address-realted">
            <div class="row">
              <div class="col-lg-4">
                <h5>Address</h5>
                <p>test</p>
              </div>
              <div class="col-lg-4">
                <h5>Phone</h5>
                <p>01236252525</p>
              </div>
              <div class="col-lg-4">
                <h5>Service Type</h5>
                <p *ngIf="order.del_mode == 'delivery'">Delivery</p>
                <p *ngIf="order.del_mode != 'delivery'">Collection</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 right-panel">


        <div class="your-order-sec box">
          <h4>Your Order</h4>
          <div class="row">
            <div class="col-lg-3">
              <div class="img">
                <img src="{{this.buisnessinfo.business_logo}}">
              </div>
            </div>
            <div class="col-lg-9">
              <div class="text">
                <a href="business/{{this.buisnessinfo.business_slug}}">
                  <h3>{{this.buisnessinfo.business_name}}</h3>
                </a>
                <p>{{this.buisnessinfo.business_address}}</p>
                <div class="star"></div>
              </div>
            </div>
          </div>
          <div class="order-div">

            <table>
              <tbody>
                <ng-container *ngFor="let item of cartitems; let ind = index">
                  <tr>
                    <td>{{ item.product_qnty }}x</td>
                    <td>
                      {{ item.product_name }} {{ item.main_pro_name }}
                      <ng-container *ngIf="item.extras !=''">
                        <ng-container *ngFor="let ex of item.extras">
                          <ng-container *ngIf="ex.show_type=='radio'">
                            <small *ngFor="let exoption of ex.option_list">
                              {{ex.option_text_name}} {{exoption.name}}
                              <div *ngFor="let condexoption of exoption.condoptiondetails">
                                <span *ngIf="condexoption.show_type=='radio'"
                                  class="pl-3 bullet">{{condexoption.choice_name_end_user}}</span>
                                <span *ngIf="condexoption.show_type=='checked'" class="pl-3">+ 1 x
                                  {{condexoption.choice_name_end_user}}</span>
                              </div>
                            </small>
                          </ng-container>
                          <ng-container *ngIf="ex.show_type=='checked'">
                            <div><small>{{ex.option_text_name}}</small></div>
                            <small *ngFor="let exoption of ex.option_list">

                              + <strong>{{exoption.qnty}}x</strong>
                              {{exoption.name}}
                              <div *ngFor="let condexoption of exoption.condoptiondetails">
                                <span *ngIf="condexoption.show_type=='radio'"
                                  class="pl-3 bullet">{{condexoption.choice_name_end_user}}</span>
                                <span *ngIf="condexoption.show_type=='checked'" class="pl-3">+ 1 x
                                  {{condexoption.choice_name_end_user}}</span>
                              </div>
                            </small>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </td>
                    <td class="right">&pound;{{ (item.product_qnty * item.sell_price).toFixed(2) }}</td>
                  </tr>
                  <tr class="comment">
                    <td colspan="4">

                      <input type="text" class="form-control" placeholder="Comment" [(ngModel)]="item.product_comment"
                        maxlength="50" />
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>



          <div class="freebies-div">

            <ng-container *ngIf="order.freedish!=undefined && order.freedish.length>0">
              <div class="mainFreedish cartPage" id="mainFreedish">
                <ng-container *ngFor="let freedish of order.freedish">
                  <ng-container *ngFor="let fd of freedish.freedish_item">
                    <div class="freeDisUnlock" *ngIf="fd.selected=='yes'">
                      <div class="freeDishItem row justify-content-between">
                        <div class="freeProd d-flex align-items-center">
                          <div class="left">
                            <p>{{freedish.qnty}}x</p>
                            <strong>{{fd.fd_name}}</strong>
                          </div>
                        </div>
                        <div class="right">
                          <strong class="text-danger"><del>&pound; {{($any(fd.fd_price | number:'1.2-2')*
                              $any(freedish.qnty | number:'1.2-2'))| number:'1.2-2'}}</del> </strong>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <table class="subtotal">
            <tbody>
              <tr>
                <td class="left"> Sub total </td>
                <td class="right"> &pound;{{ grosstotal.toFixed(2) }} </td>
              </tr>
              <tr *ngIf="order.del_mode == 'delivery'">
                <td class="left"> Delivery Fee </td>
                <td class="right"> &pound;{{ delivery_charg.toFixed(2) }} </td>
              </tr>
              <tr>
                <td class="left"> Service charge </td>
                <td class="right"> &pound;{{ service_charg.toFixed(2) }} </td>
              </tr>
              <ng-container *ngIf="baggage_charg>0">
                <tr>
                  <td class="left"> Baggage Charge </td>
                  <td class="right"> &pound;{{baggage_charg | number:'1.2-2'}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <table class="total">
            <tbody>
              <tr>
                <td class="left">Total</td>
                <td class="right">&pound;{{ checkout_amount.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>


        </div>

        <div class="freebies-discount" *ngIf="order.freedish!=undefined && order.freedish.length>0">
          <table>
            <tbody>
              <tr>
                <td class="left">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20" fill="#228f00">
                    <path
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <p>You Saved On Freebies</p>
                </td>
                <td class="right">
                  <p>&pound; {{freedishSaveAmount | number:'1.2-2'}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="send-me-offer">

          <div class="form-check" *ngIf="!this.user.sms_subscription">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="order.sms_subscription">
            <label class="form-check-label" for="exampleCheck1">Get SMS updates on order.</label>
          </div>
          <div class="form-check" *ngIf="!this.user.bookyup_subscription">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="order.bookyup_subscription">
            <label class="form-check-label" for="exampleCheck1">Send me offers from BookYup.</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="order.bs_subscription">
            <label class="form-check-label" for="exampleCheck1">Send me offers from
              {{this.buisnessinfo.business_name}}.</label>
          </div>

        </div>

        <div class="pay-method">
          <div class="pick-time-sec">
            <h5 class="cap">{{order.del_mode}} Time</h5>
            <div class="button-div">

              <ng-container *ngFor="let time of timearray; let in=index">
                <div class="col-lg-3 col-md-4 col-sm-6 col-6" *ngIf="in < showlimit">
                  <div class="fldarea custom-checkbox2">
                    <label for="{{ 'groupName' + in }}">
                      <input type="radio" id="{{ 'groupName' + in }}" name="delivery-time" [(ngModel)]="order.slot"
                        value="{{time}}">
                      <span class="checkmark">{{ time }}</span>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="show-some-time">
              <input type="button" class="btn btn-prime btn-sm" (click)="showmore()" value="Show more times"
                *ngIf="timearray.length > showlimit" />
            </div>
          </div>

          <h5>Select Payment Method</h5>
          <div class="row pay-order">
            <form>
              <div class="form-check active">
                <div id="respMenu" class="myform align-items-center justify-content-between"
                  data-menu-style="accordion">
                  <div *ngFor="let paymodedata of buisnessinfo.payment_permission[0].order">
                    <div class="fldarea custom-checkbox checkbox-btn">
                      <input type="radio" id="{{paymodedata.value}}" name="dmode" value="{{paymodedata.value}}"
                        [(ngModel)]="paymode" (change)="changePaytype(paymodedata.value)" />
                      <label for="{{paymodedata.value}}">
                        <i class="las la-check"></i>
                        {{ paymodedata.leble | titlecase }}
                      </label>
                    </div>
                  </div>
                </div>
                <div *ngIf="paymentmodeerror" class="error-txt">**Payment method is required</div>
              </div>

            </form>
            <div class="button-sec mt-2">

              <button class="order-now" (click)="checkout()">ORDER NOW</button>
            </div>
          </div>
        </div>



      </div>

    </div>
  </div>
</section> -->
<section class="checkout-all">
  <div class="inner-banner">
    <div class="inner-banner"
      style="background-image: url(./assets/images/inner-banner/banner-inner.jpg);background-repeat: no-repeat;">
    </div>
    <div class="inner-banner-text">
      <h1 class="type">Check <span>Out</span></h1>

    </div>

  </div>

  <div class="container checkout">


    <div class="button-sec">
      <div class="back">
        <a href="javascript:void(0)" type="button" (click)="goBack()">
          <span><svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#f03157" viewBox="0 0 320 512">
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg></span>
          <span>Back</span>
        </a>
      </div>
    </div>



    <div class="checkout-aside-1">
      <div class="row">

        <div class="col-lg-6">
          <div class="checkout-bg">
            <!-- <div class="switch-toggle">
              <div class="button">
                <input type="radio" id="business_collection_radio_delivery" name="check-substitution-2" />
                <label class="btn btn-default" for="business_collection_radio_delivery">
                  <span class="total-div mt-2">
                    <div class="left-div"><app-svg-icon iconname="delivery-car-icon"></app-svg-icon></div>
                    <div class="right-div">
                      <h6>Delivery</h6>

                    </div>
                  </span>
                </label>
              </div>
              <div class="button">
                <input type="radio" id="business_collection_radio_pickup" name="check-substitution-2" />
                <label class="btn btn-default" for="business_collection_radio_pickup">
                  <span class="total-div mt-2">
                    <div class="left-div">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                        <path
                          d="M368.6 96l76.8 96L288 192l0-96 80.6 0zM224 80l0 112L64 192c-17.7 0-32 14.3-32 32l0 64c-17.7 0-32 14.3-32 32s14.3 32 32 32l33.1 0c-.7 5.2-1.1 10.6-1.1 16c0 61.9 50.1 112 112 112s112-50.1 112-112c0-5.4-.4-10.8-1.1-16l66.3 0c-.7 5.2-1.1 10.6-1.1 16c0 61.9 50.1 112 112 112s112-50.1 112-112c0-5.4-.4-10.8-1.1-16l33.1 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64c0-17.7-14.3-32-32-32l-48.6 0L418.6 56c-12.1-15.2-30.5-24-50-24L272 32c-26.5 0-48 21.5-48 48zm0 288a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm288 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0z" />
                      </svg>
                    </div>
                    <div class="right-div">
                      <h6>Pick Up</h6>

                    </div>

                  </span>
                </label>
              </div>
            </div> -->

           <!--  <label class="dropdown">
              <div class="dd-button">
                <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z" />
                  </svg>
                </div>
                <div class="text"><span>Date</span>
                  <br>
                  <p>02.09 - Today</p>
                </div>


              </div>


              <input type="checkbox" class="dd-input" id="test">

              <ul class="dd-menu">
                <li>Action</li>
              </ul>

            </label> -->

            <!-- <label class="dropdown">

              <div class="dd-button">


                <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                  </svg>
                </div>
                <div class="text"><span>{{order.del_mode}} Time</span>
                  <br>
                  <p>02.09 - Today</p>
                </div>


              </div>


              <input type="checkbox" class="dd-input" id="test">

              <ul class="dd-menu">
                <li>Action</li>
                <li>Action</li>
                <li>Action</li>
                <li>Action</li>
              </ul>

            </label> -->
            
            <div class="col">
              <div class="ordername">
                <strong><h3>Service type</h3></strong>
                <div *ngIf="order.del_mode == 'delivery'">Delivery</div>
                <div *ngIf="order.del_mode != 'delivery'">Collection</div>
              </div>
            </div>
            <div class="fldarea"><label class="cap">{{order.del_mode}} Time</label></div>
            <select id="select-time" class="form-select" name="orderSlot" [(ngModel)]="order.slot" placeholder="Preferred Time">
              <option value="">Select Time</option>
              <ng-container *ngFor="let time of timearray; let in=index">
                <option value="{{time}}">{{time}}</option>
              </ng-container>
            </select>

            <!-- <div class="add-item mt-2">
              <div class="row">
                <div class="col-lg-9">
                  <h6 class="mt-3">Order summary</h6>

                </div>
                <div class="col-lg-3">
                  <div class="addbtn ">
                    <a href="" class="d-flex justify-content-">+ <span> Add Button</span></a>
                  </div>

                </div>

              </div>
            </div> -->

            <div class="tab-function mt-3">

              <div class="faq-drawer">
                <input class="faq-drawer__trigger" id="faq-drawer" type="checkbox" /><label class="faq-drawer__title"
                  for="faq-drawer">
                  <div class="item-tab">
                    <div class="row mt-3">
                      <div class="col-lg-10"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                          <path
                            d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                        </svg> <span class="item-acor">Your order details</span> </div>
                      <!-- <div class="col-lg-2"><span>items </span></div> -->
                    </div>
                  </div>
                </label>
                <div class="faq-drawer__content-wrapper">
                  <div class="faq-drawer__content">
                    <ng-container *ngFor="let item of cartitems; let ind = index">
                    <div class="row">
                      <div class="col-lg-1 col-sm-1">
                        <div class="icon-tab"><span>{{ item.product_qnty }}x</span></div>

                      </div>
                        <div class="col-lg-11 col-sm-11">
                          <div class="tab-content1">
                           
                            <ng-container *ngIf="item.extras !=''">
                              <ng-container *ngFor="let ex of item.extras">
                                <ng-container *ngIf="ex.show_type=='radio'">
                                  <small *ngFor="let exoption of ex.option_list">
                                    {{ex.option_text_name}} {{exoption.name}}
                                    <div *ngFor="let condexoption of exoption.condoptiondetails">
                                      <span *ngIf="condexoption.show_type=='radio'"  class="pl-3 bullet">{{condexoption.choice_name_end_user}}</span>
                                      <span *ngIf="condexoption.show_type=='checked'"  class="pl-3">+ 1 x {{condexoption.choice_name_end_user}}</span>
                                    </div>
                                  </small>
                                </ng-container>
                                <ng-container *ngIf="ex.show_type=='checked'">
                                  <div><small>{{ex.option_text_name}}</small></div>
                                  <small *ngFor="let exoption of ex.option_list">
                                    
                                    + <strong>{{exoption.qnty}}x</strong>
                                    {{exoption.name}}
                                    <div *ngFor="let condexoption of exoption.condoptiondetails">
                                      <span *ngIf="condexoption.show_type=='radio'"  class="pl-3 bullet">{{condexoption.choice_name_end_user}}</span>
                                      <span *ngIf="condexoption.show_type=='checked'"  class="pl-3">+ 1 x {{condexoption.choice_name_end_user}}</span>
                                    </div>
                                  </small>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                            <!-- <h6>Choose One</h6>
                            <span>Vegetable</span>
  
                            <h6>Choose One</h6>
                            <span>Vegetable</span>
  
                            <h6>Choose One</h6>
                            <span>Vegetable</span> -->
                            <!-- <div class="row">
                              <div class="col-lg-2">
                                <p>Subtotal</p>
                              </div>
                            </div> -->
                              <div class="col-lg-12 d-flex justify-content-between">
                                <h4>{{ item.product_name }} {{ item.main_pro_name }}</h4>
                                <span>&pound;{{ (item.product_qnty * item.sell_price).toFixed(2) }}</span>
                              </div>
                            <form>
                              <div class="input-area">
                                <textarea class="form-control form-control-sm mb-3" rows="1" name="product_comment"
                                  placeholder="Comment" [(ngModel)]="item.product_comment" maxlength="50"></textarea>
                              </div>
                            </form>
                          </div>
                        </div>
                    </div>
                  </ng-container>


                    <!-- <div class="row">
                      <div class="col-lg-1 col-sm-1">
                        <div class="icon-tab"><span>1</span></div>

                      </div>
                      <div class="col-lg-11 col-sm-11">
                        <div class="tab-content1">
                          <h4>Balti Dishes</h4>
                          <h6>Choose One</h6>
                          <span>Vegetable</span>

                          <h6>Choose One</h6>
                          <span>Vegetable</span>

                          <h6>Choose One</h6>
                          <span>Vegetable</span>
                          <div class="row">
                            <div class="col-lg-2">
                              <p>Subtotal</p>
                            </div>
                            <div class="col-lg-10 d-flex justify-content-end">
                              <p>£6.50</p>
                            </div>

                          </div>
                          <form>
                            <div class="input-area">

                              <textarea class="form-control form-control-sm mb-3" rows="1"
                                placeholder="Small textarea"></textarea>

                            </div>

                          </form>


                        </div>



                      </div>



                    </div> -->


                  </div>
                </div>
                <!-- <div class="faq-drawer">
                  <input class="faq-drawer__trigger" id="faq-drawer-2" type="checkbox" /><label
                    class="faq-drawer__title" for="faq-drawer-2">
                    <div class="item-tab">
                      <div class="row mt-3">
                        <div class="col-lg-10"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                              d="M0 80L0 229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7L48 32C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                          </svg> <span class="item-acor">Promotion code</span> </div>
                        <div class="col-lg-2"><span>items </span></div>
                      </div>
                    </div>

                  </label>
                  <div class="faq-drawer__content-wrapper ">
                    <div class="faq-drawer__content checkout-aside-1">
                      <h4>Add promo code</h4>
                      <p>We need your e-mail to validate promotion codes</p>
                      <form>
                        <div class="input-area">
                          <label for=":r67:" class="sc-c89d7496-1 dUOnxx">E-mail *</label>
                          <input placeholder="johndoe@example.com" type="email" name="email" class="form-control">

                        </div>
                        <div class=""><button class="btn mt-3">Save And Continue</button></div>
                      </form>

                    </div>
                  </div>
                </div> -->

                <div class="right-panel">


                  <div class="your-order-sec">
                    <ng-container *ngIf="order.freedish!=undefined && order.freedish.length>0">
                      <div class="mainFreedish cartPage" id="mainFreedish">
                        <ng-container *ngFor="let freedish of order.freedish">
                          <ng-container *ngFor="let fd of freedish.freedish_item">
                            <div class="freeDisUnlock" *ngIf="fd.selected=='yes'">
                              <div class="freebies-div">
                                <div class="left">
                                  <p>{{freedish.qnty}} x <span>{{fd.fd_name}}</span> </p>
                                </div>
                                <div class="right">
                                  <p>&pound; {{($any(fd.fd_price | number:'1.2-2')* $any(freedish.qnty | number:'1.2-2'))| number:'1.2-2'}}</p>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                    <table class="subtotal">
                      <tbody>
                        <tr>
                          <td class="left"> Sub total </td>
                          <td class="right">&pound;{{ grosstotal.toFixed(2) }}</td>
                        </tr>
                        <tr *ngIf="order.del_mode == 'delivery'">
                          <td class="left">Delivery Fee</td>
                          <td class="right">&pound;{{ delivery_charg.toFixed(2) }}</td>
                        </tr>
                        <tr>
                          <td class="left"> Service charge </td>
                          <td class="right">&pound;{{ service_charg.toFixed(2) }}</td>
                        </tr>            
                        <ng-container *ngIf="baggage_charg>0">
                          <tr>
                            <td class="left"> Baggage Charge </td>
                            <td class="right">&pound;{{baggage_charg | number:'1.2-2'}}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="order.discount.discountvalue > 0">
                          <td class="left"> Discount </td>
                          <td class="right">- &pound;{{order.discount.discountvalue | number:'1.2-2'}}</td>
                        </tr>    
                      </tbody>
                    </table>

                    <table class="total">
                      <tbody>
                        <tr>
                          <td class="left">Total</td>
                          <td class="right">&pound;{{ checkout_amount.toFixed(2) }}</td>
                        </tr>
                      </tbody>
                    </table>


                  </div>

                  <div class="freebies-discount send-me-offer mt-3" *ngIf="order.freedish!=undefined && order.freedish.length>0">
                    <table>
                      <tbody>
                        <tr>
                          <td class="left">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20"
                              fill="#228f00">
                              <path
                                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z">
                              </path>
                            </svg>
                            <p>You Saved On Freebies</p>
                          </td>
                          <td class="right">
                            <p>&pound; {{freedishSaveAmount | number:'1.2-2'}}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="send-me-offer ">
                    <form>
                      <div class="form-check" *ngIf="user != undefined && user.sms_subscription != undefined && !user.sms_subscription">
                        <input type="checkbox" class="form-check-input" name="sms_subscription" id="exampleCheck1" [(ngModel)]="order.sms_subscription">
                        <label class="form-check-label" for="exampleCheck1">Get SMS updates on order.</label>
                      </div>
                      <div class="form-check" *ngIf="user != undefined && user.bookyup_subscription != undefined && !user.bookyup_subscription">
                        <input type="checkbox" class="form-check-input" name="bookyup_subscription" id="exampleCheck1" [(ngModel)]="order.bookyup_subscription">
                        <label class="form-check-label" for="exampleCheck1">Send me offers from BookYup.</label>
                      </div>
                      <div class="form-check" *ngIf="buisnessinfo != undefined && buisnessinfo.business_name != undefined">
                        <input type="checkbox" class="form-check-input" name="bs_subscription" id="exampleCheck1" [(ngModel)]="order.bs_subscription">
                        <label class="form-check-label" for="exampleCheck1">Send me offers from {{buisnessinfo.business_name}}.</label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="checkout-aside-1 checkout-bg">
            <div class="row">
              <div class="col-lg-8 "><h3>Your Information</h3></div>
              <div class="col-lg-4" *ngIf="user != undefined && user.user_id != undefined && user.user_id !='0'">
                <div class="add-button-checkout d-flex justify-content-end">
                  <button class="btn" (click)="addnew()">Add Address</button>
                </div>
              </div>
            </div>
            
            <!-- <span>have an account? <a href="#">Login</a></span> -->
            <ng-container *ngIf="user != undefined && user.user_id != undefined && user.user_id !='0'">
              <div class="left-panel" *ngIf="addresslist !='null' && addresslist != ''">
                <div class="time-part-selection">
                  <div class="address-form-add mt-3">
                    <form class="address-form">
                      <div class="row">
                        <!-- <div class="col-lg-4">
                          <div class="form-check">
                            <input type="radio" class="form-check-input" id="exampleCheck1" (change)="getaddress(user.address_book)">
                            <label class="form-check-label" for="exampleCheck1">
                              <h5>Default Address</h5>
                              <p>{{ this.user.address_book.name }}</p>
                              <p>{{ this.user.address_book.address }} {{ this.user.address_book.address2 }} {{ this.user.address_book.city }}</p>
                              <p>{{ this.user.address_book.zipcode }}</p>
                              <p>{{ this.user.address_book.mobile_no }}</p>
                              <p>{{ this.user.address_book.email }}</p>
                            </label>
                            <a class="edit" href="javascript:void(0)">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z">
                                </path>
                              </svg>
                            </a>
                          </div>
                        </div> -->
                        
                          <!-- <div class="col-lg-4" *ngFor="let aditem of addresslist">
                            <div class="form-check">
                              <input type="radio" class="form-check-input" value="aditem.address_id" name="addrList-{{aditem.address_id}}" id="addressradio-{{aditem.address_id}}" [checked]="aditem.address_id == deladdress.address_id" (change)="getaddress(aditem)">
                              <label class="form-check-label" for="exampleCheck1">
                                <h5 *ngIf="aditem.is_default == 'yes'">Default Address</h5>
                                <h5 *ngIf="aditem.is_default == 'no'">Another Address</h5>
                                <p>{{ aditem.name }}</p>
                                <p>{{ aditem.address }} {{ aditem.address2 }} {{ aditem.city }}</p>
    
                                <p>{{ aditem.zipcode }}</p>
                                <p>{{ aditem.mobile_no }}</p>
                                <p>{{ aditem.email }}</p>
                              </label>
                              <a class="edit" href="javascript:void(0)">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path
                                    d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z">
                                  </path>
                                </svg>
                              </a>
                            </div>
                          </div>
  
                        <div class="col-lg-4">
                          <div class="form-check">
                            <div class="svg-sidebar">
                              <a href="javascript:void(0)" (click)="addnew()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                  <path
                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                                </svg></a>
                            </div>
                          </div>
                        </div> -->
                        
                        <div class="owl-carousel">
                          <div class="item" *ngFor="let aditem of addresslist; let k=index">
                            <div class="form-check">
                              <input type="radio" class="form-check-input" value="aditem.address_id" name="addrList-{{aditem.address_id}}" id="addressradio-{{aditem.address_id}}" [checked]="aditem.address_id == deladdress.address_id" (change)="getaddress(aditem)">
                              <label class="form-check-label" for="exampleCheck1">
                                <h5 *ngIf="aditem.is_default == 'yes'">Default Address</h5>
                                <h5 *ngIf="aditem.is_default == 'no'">Another Address</h5>
                                <span>{{ aditem.name }}</span>
                                <span>{{ aditem.address }} {{ aditem.address2 }} {{ aditem.city }}</span>
    
                                  <span>{{ aditem.zipcode }}</span>
                                    <span>{{ aditem.mobile_no }}</span>
                                    <span>{{ aditem.email }}</span>
                              </label>
                              <a class="edit" href="javascript:void(0)" (click)="editAddress(aditem)">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path
                                    d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z">
                                  </path>
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div class="item">
                            <div class="form-check">
                              <div class="svg-sidebar">
                                <a href="javascript:void(0)" (click)="addnew()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path
                                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                                  </svg></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="addressDetails != undefined && addressDetails != ''">
              <!-- {{addressDetails}} -->
              <div class="left-panel">
                <div class="time-part-selection">
                  <div class="address-form-add mt-3">
                    <form class="address-form">
                      <div class="row">
                        <div class="col-lg-4">
                          <div class="form-check">
                            <!-- <input type="radio" class="form-check-input" id="exampleCheck1" (change)="getaddress(user.address_book)"> -->
                            <label class="form-check-label" for="exampleCheck1">
                              <p>{{ addressDetails.name }}</p>
                              <p>{{addressDetails.address }}</p>
                              <p>{{ addressDetails.postCode }}</p>
                              <p>{{ addressDetails.phNo }}</p>
                              <p>{{ addressDetails.email }}</p>
                            </label>
                            <a class="edit" href="javascript:void(0)" (click)="editAddress(addressDetails)">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z">
                                </path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="(user != undefined && user.user_id != undefined && user.user_id =='0' && showForm) || (user != undefined && user.user_id != undefined && user.user_id >'0' && showForm)">
              <form [formGroup]="deliveryAddresForm" (ngSubmit)="saveaddress()">
                <div class="input-area">
                  <label for=":r64:" class="sc-c89d7496-1 dUOnxx">Name *</label>
                  <input placeholder="Name" type="text" formControlName="name" autocomplete="given-name" name="firstName" class="form-control">
                </div>
                <div class="alert alert-danger" *ngIf="(addressDelForm['name']?.touched  && addressDelForm['name']?.errors?.['required'])">
                  Name is required
                </div>
                <div class="input-area">
                  <label for=":r64:" class="sc-c89d7496-1 dUOnxx">Email *</label>
                  <input placeholder="Email" type="email" formControlName="email" autocomplete="given-name" name="email" class="form-control">
                </div>
                <div class="alert alert-danger" *ngIf="(addressDelForm['email']?.touched && addressDelForm['email']?.errors?.['required'])">
                  Email is required
                </div>
                <div class="alert alert-danger" *ngIf="(addressDelForm['email']?.touched && addressDelForm['email']?.errors?.['email'])">
                  Please enter valid email
                </div>
                <div class="input-area">
                  <label for=":r66:" class="sc-c89d7496-1 dUOnxx">Mobile Number. *</label>
                  <input placeholder="Phone Number" formControlName="phNo" name="phone" class="form-control">
                </div>
                <div class="alert alert-danger" *ngIf="(addressDelForm['phNo']?.touched && addressDelForm['phNo']?.errors?.['required'])">
                  Mobile Number is required
                </div>
                <div class="alert alert-danger" *ngIf="(addressDelForm['phNo']?.touched && addressDelForm['phNo']?.errors?.['pattern'])">
                  Phone number must have 11 digit and a leading 0
                </div>
                <div class="input-area" *ngIf="delivery_type=='delivery'" pattern="^(?=.*[a-zA-Z])(?=.{5,}).*$">
                  <label for=":r67:" class="sc-c89d7496-1 dUOnxx">Address *</label>
                  <input placeholder="Address" formControlName="address" type="text" name="address" class="form-control">
                </div>
                <div class="input-area" *ngIf="delivery_type=='pickup'" pattern="^^(?!\s*$).+">
                  <label for=":r67:" class="sc-c89d7496-1 dUOnxx">Address *</label>
                  <input placeholder="Address" formControlName="address" type="text" name="address" class="form-control">
                </div>
                <div class="alert alert-danger" *ngIf="(addressDelForm['address']?.touched && addressDelForm['address']?.errors?.['required'])">
                  Address is required
                </div>
                <div class="input-area">
                  <label for=":r67:" class="sc-c89d7496-1 dUOnxx">Postcode *</label>
                  <input placeholder="Postcode" formControlName="postCode" type="text" name="Postcode" class="form-control">
                </div>
                <div class="alert alert-danger" *ngIf="(addressDelForm['postCode']?.touched && addressDelForm['postCode']?.errors?.['required'])">
                  Postcode is required
                </div>
                <div class="error-text" *ngIf="error !=''">{{ error }}</div>
                <div class="button-sec mt-2">
                  <input type="submit" name="" value="Save & Proceed" class="btn btn-prime">
                </div>
                <!-- <p class="mt-3">You agree to our Terms of Use and confirm that you have read our Privacy Promise by
                  creating
                  an account, placing an order or making a reservation.</p>
                <div class="right-panel">
                  <div class="send-me-offer mt-3">
                    <form>
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        <label class="form-check-label" for="exampleCheck1">Send me offers from Masala Club Balsall
                          Common.</label>
                      </div>
                    </form>
                  </div>
                </div> -->
              </form>
            </ng-container>
            <ng-container *ngIf="orderBtn">
              <div class="checkout-all">
                <h6 class="innerInfo mt-2"><strong>Select Payment Method:</strong></h6>
                <div class="checkout bottom mt-2">
                  <div class="switch-toggle left" *ngIf="buisnessinfo != undefined && buisnessinfo.payment_permission != undefined">
  
                    <div class="button" *ngFor="let paymodedata of buisnessinfo.payment_permission[0].order">
                      <input type="radio" id="{{paymodedata.value}}" name="dmode" value="{{paymodedata.value}}" [(ngModel)]="paymode" (change)="changePaytype(paymodedata.value)" />
                      <label class="btn btn-default" for="{{paymodedata.value}}">
                        <span class="total-div mt-2">
                          <div class="left-div">
                            <app-svg-icon iconname="paypal" *ngIf="paymodedata.value.toLowerCase()=='paypal'"></app-svg-icon>
                            <app-svg-icon iconname="cash" *ngIf="paymodedata.value.toLowerCase()=='cod'"></app-svg-icon>
                            <app-svg-icon iconname="card" *ngIf="paymodedata.value.toLowerCase()!='paypal' && paymodedata.value.toLowerCase()!='cod'"></app-svg-icon>
                          </div>
                          <div class="right-div">
                            <h6>{{ paymodedata.leble | titlecase }}</h6>
                          </div>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="paymentmodeerror" class="error-txt">**Payment method is required</div>
                  <div class="mt-2">
                    <button class="btn order-now" [disabled]="!orderBtn" (click)="checkout()">Order Now</button>
                  </div>
                </div>
              </div>
            </ng-container>


          </div>

        </div>

      </div>





    </div>
  </div>



</section>
<ng-template #confirmation_modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update !!!</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body myform" size="lg">
    <div class="row">
      <div class="col-md-12">
        <p>We have updated the cart as some of the item value or discount has changed !!</p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <div class="btn btn-outline-dark" (click)="refreshpage()">Close</div>
    <button type="button" class="btn btn-dark" (click)="refreshpage()">OK</button>
  </div>
</ng-template>
<app-footer />