<app-header />
<div class="error404">
  <div>
      <h1>404</h1>
      <p>We are sorry but the page you requested was not found.</p>
      <div class="d-flex align-items-center justify-content-center">
          <a class="btn btn-prime" routerLink="/">Go home</a>
          <a class="btn btn-outline-dark" routerLink="/contact-us">Contact us</a>
      </div>
  </div>
</div>
<app-footer />