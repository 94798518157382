<!-- common part for all pages start -->
<!-- header section start here -->
<app-header />

<!-- header section end here -->
<!-- common part for all pages end -->


<!-- menu page start here -->
<section class="menu-banner" id="banner" [ngStyle]="{'background-image': 'url(' + bannerUrl + ')'}">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 banner-main">
        <div class="banner-logo-details row">
          <div class="logo-sec col-lg-2">
            <img src="{{business_logo}}" alt="">
          </div>
          <div class="banner-details col-lg-8">
            <h1>{{business_name}}</h1>
            <p>{{business_address}}<ng-container *ngIf="business_colony!=''">,
                {{business_colony}}</ng-container><ng-container *ngIf="business_city!=''">,
                {{business_city}}</ng-container></p>
            <p>
              <!-- <ng-container *ngFor="let subcat of subcategory;">
                <span>{{subcat}}</span>
              </ng-container> -->
              <span>{{subcategory}}</span>
            </p>
            <div class="star-details">
              <div class="star">
                <app-svg-icon iconname="star-rating-full"></app-svg-icon>
                <app-svg-icon iconname="star-rating-full"></app-svg-icon>
                <app-svg-icon iconname="star-rating-full"></app-svg-icon>
                <app-svg-icon iconname="star-rating-half"></app-svg-icon>
                <app-svg-icon iconname="star-rating-empty"></app-svg-icon>
              </div>
              <p><!-- <ng-template #bs_rating_star let-fill="fill">
                <span class="star" [class.full]="fill === 100">
                  <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                </span>
              </ng-template>
              <ngb-rating [(rate)]="4.3" [starTemplate]="bs_rating_start" [readonly]="true" [max]="5"></ngb-rating> -->
              </p>
              <p>3.5/4.5</p>
              <p>Very Good</p>
            </div>
          </div>
          <div class="item-react col-lg-2">
            <a href="#" tabindex="-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-heart"
                viewBox="0 0 16 16">
                <path
                  d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15">
                </path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="menu-fulldetails">
  <div class="container">
    <div class="row menu-fulldetails-row">
      <!-- left menu category start -->
       <ng-container *ngIf="menuLoader">
        <div class="menu-items col-lg-3">
          <app-loader loadername='menu-left-menucategory'></app-loader>
        </div>
       </ng-container>
       <ng-container *ngIf="!menuLoader">
        <div class="menu-items" [ngClass]="selected_tab=='book'?'menu-category-hide':'col-lg-3'">
          <ul>
            <li *ngFor="let cat of category"><i class="fa-regular fa-circle"></i>
              <a href="javascript:void(0)" (click)="openOneMenuCategory(cat.menucategory_id)"
                [class.active]="cat.menucategory_id == opendivid">{{cat.menucategory_name}}</a>
            </li>
          </ul>
        </div>
      </ng-container>
      <!-- left menu category end -->
      <div id="menu-2" class="menu-details-all" [ngClass]="selected_tab=='book'?'col-lg-8':'col-lg-5'">

        <!-- middle menu tab start -->
        <ng-container *ngIf="menuLoader">
          <div>
            <app-loader loadername='menu-center-menu-tab'></app-loader>
          </div>
         </ng-container>
         <ng-container *ngIf="!menuLoader">
          <ul class="nav nav-tabs menu-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link" [ngClass]="selected_tab=='menu'?'active':''" id="menu_tab" data-bs-toggle="tab"
                data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"
                (click)="settab('menu')">
                <img class="black" src="../../../assets/images/menu/menu-new.png" alt="">
                <img class="white" src="../../../assets/images/menu/menu1new.png" alt="">
                <p>Menu</p>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" [ngClass]="selected_tab=='review'?'active':''" id="review_tab" data-bs-toggle="tab"
                data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"
                (click)="settab('review')">
                <img class="black" src="../../../assets/images/menu/review-new.png" alt="">
                <img class="white" src="../../../assets/images/menu/review1.png" alt="">
                <p>Review</p>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" [ngClass]="selected_tab=='info'?'active':''" id="info_tab" data-bs-toggle="tab"
                data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"
                (click)="settab('info')">
                <img class="black" src="../../../assets/images/menu/info.png" alt="">
                <img class="white" src="../../../assets/images/menu/info1.png" alt="">
                <p>Info</p>
              </button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="booking.status=='yes'">
              <button class="nav-link" [ngClass]="selected_tab=='book'?'active':''" id="booking_tab" data-bs-toggle="tab"
                data-bs-target="#book" type="button" role="tab" aria-controls="book" aria-selected="false"
                (click)="settab('book')">
                <img class="black" src="../../../assets/images/menu/dinner-table.png" alt="">
                <img class="white" src="../../../assets/images/menu/dinner-table1.png" alt="">
                <p>Book a table</p>
              </button>
            </li>
            <li class="nav-item crd-display" role="presentation">
              <button class="nav-link" href="" id="card-page-tab" data-bs-toggle="tab" data-bs-target="#card-page"
                type="button" role="tab" aria-controls="card-page" aria-selected="false">
                <div class="img-div">
                  <img class="black" src="../../../assets/images/menu/grocery-store.png" alt="">
                  <img class="white" src="../../../assets/images/menu/grocery-store1.png" alt="">
                  <span>1</span>
                </div>
                <p>cart</p>
              </button>
            </li>
          </ul>
        </ng-container>
        <!-- middle menu tab end -->
        <div class="tab-content" id="myTabContent">
          <!-- menu container -->
           
          <ng-container *ngIf="menuLoader">
            <div>
              <app-loader loadername='menu-center-menu-list'></app-loader>
            </div>
          </ng-container>
          <ng-container *ngIf="!menuLoader">
            <div class="tab-pane menu-offer details fade show active" id="menu_div" role="tabpanel"
              aria-labelledby="menu-tab">
              <div id="owl_carousel_discount" class="owl-carousel owl-theme" *ngIf="default_discount != undefined && default_discount.delivery != undefined && default_discount.delivery.discounttext != null">
                <div class="item">
                  <div class="same-div">
                    <a href="javascript:void(0)">
                      <div class="svg-div">
                        <app-svg-icon iconname="discount"></app-svg-icon>
                      </div>
                      <div class="text-div">
                        <h6>GET <ng-container
                            *ngIf="default_discount.delivery.payby=='price'">&pound;</ng-container>{{default_discount.delivery.commonrate}}<ng-container
                            *ngIf="default_discount.delivery.payby=='percentage'">%</ng-container> OFF</h6>
                        <p>{{default_discount.delivery.discounttext}}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="item"
                  *ngIf="default_discount != undefined && default_discount.pickup != undefined && default_discount.pickup.discounttext != null">
                  <div class="same-div">
                    <a href="javascript:void(0)">
                      <div class="svg-div">
                        <app-svg-icon iconname="discount"></app-svg-icon>
                      </div>
                      <div class="text-div">
                        <h6>GET <ng-container
                            *ngIf="default_discount.pickup.payby=='price'">&pound;</ng-container>{{default_discount.pickup.commonrate}}<ng-container
                            *ngIf="default_discount.pickup.payby=='percentage'">%</ng-container> OFF</h6>
                        <p>{{default_discount.pickup.discounttext}}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="item"
                  *ngIf="discount_delivery_charge_waiver != undefined && discount_delivery_charge_waiver != null && discount_delivery_charge_waiver.discounttext != undefined && discount_delivery_charge_waiver.discounttext != null && discount_delivery_charge_waiver.discounttext != ''">
                  <div class="same-div">
                    <a href="javascript:void(0)">
                      <div class="svg-div">
                        <app-svg-icon iconname="discount"></app-svg-icon>
                      </div>
                      <div class="text-div">
                        <h6>{{discount_delivery_charge_waiver.discounttext}}</h6>
                      </div>
                    </a>
                  </div>
                </div>
                <ng-container *ngFor="let fdtxt of freedishWelcomeTextArr; let x = index">
                  <div class="item">
                    <div class="same-div">
                      <a href="javascript:void(0)">
                        <div class="svg-div">
                          <app-svg-icon iconname="discount"></app-svg-icon>
                        </div>
                        <div class="text-div">
                          <h6>{{fdtxt}}</h6>
                          <!-- <p>On both delivery & pickup orders above £15.00</p> -->
                        </div>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="search-bar">
                <form>
                  <div class="form-group" *ngIf="!search.searchmode">
                    <input type="text" class="form-control" id="searchMenu" name="searchMenu" aria-describedby="emailHelp"
                      placeholder="Search your food" [(ngModel)]="search.serchtxt">
                    <app-svg-icon iconname="search"></app-svg-icon>
                    <a href="javascript:void(0)" (click)="searchboxon()">Search</a>
                  </div>
                </form>
              </div>

              <div class="accordian-part mt-3" *ngIf="!search.searchmode">
                <ng-container *ngFor="let cat of category">
                  <div class="accordion-contianer business" id="acc_{{cat.menucategory_id}}">
                    <button class="accordion" (click)="menu_open_close_accrodian(cat.menucategory_id)">
                      <span>{{cat.menucategory_name}}</span>
                      <span><app-svg-icon iconname="down-arrow"></app-svg-icon></span>
                    </button>
                    <div class="panel mt-3 menu-pro-cont-div" id="panel_{{cat.menucategory_id}}">
                      <span *ngIf="cat.menucategory_desc!=''">{{cat.menucategory_desc}}</span>
                      <ng-container *ngFor="let pop of cat.product; let ind = index">
                        <div *ngIf="pop.has_subproduct=='no'">

                          <h4 class="mt-2">{{pop.product_name}}</h4>
                          <span *ngIf="pop.product_desc!=''">{{pop.product_desc}}</span>
                          <!-- <h5>Combo item</h5> -->

                          <div class="row product-details-list">
                            <div class="name-div col-8">
                              <h6>{{pop.product_name}}</h6>
                              <h6><span>&pound;{{pop.product_price}}</span></h6>
                            </div>
                            <div class="button-div col-4" *ngIf="pop.has_option =='yes'">
                              <a href="javascript:void(0)" (click)="openOptionPop(pop.product_option)">
                                <app-svg-icon iconname="plus"></app-svg-icon>
                                <p *ngIf="pop.product_selected_quentity > 0">{{pop.product_selected_quentity}}</p>
                              </a>
                            </div>
                            <div class="button-div col-4" *ngIf="pop.has_option =='no'">
                              <a href="javascript:void(0)" (click)="addtocart(pop, 'm',null)">
                                <app-svg-icon iconname="plus"></app-svg-icon>
                                <p *ngIf="pop.product_selected_quentity > 0">{{pop.product_selected_quentity}}</p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="pop.has_subproduct=='yes'">

                          <h4 class="mt-2">{{pop.product_name}}</h4>
                          <span *ngIf="pop.product_desc!=''">{{pop.product_desc}}</span>
                          <!-- <h5>Combo item</h5> -->
                          <ng-container *ngFor="let sub of pop.sub_product; let subind = index">
                            <div class="row product-details-list">
                              <div class="name-div col-8">
                                <h6>{{sub.product_name}}</h6>
                                <h6><span>&pound;{{sub.product_price}}</span></h6>
                              </div>
                              <div class="button-div col-4" *ngIf="sub.has_option=='yes'">
                                <a href="javascript:void(0)" (click)="openOptionPop(sub.product_option)">
                                  <app-svg-icon iconname="plus"></app-svg-icon>
                                  <p *ngIf="sub.product_selected_quentity > 0">{{sub.product_selected_quentity}}</p>
                                </a>
                              </div>
                              <div class="button-div col-4" *ngIf="sub.has_option=='no'">
                                <a href="javascript:void(0)" (click)="addtocart(sub, 's', pop)">
                                  <app-svg-icon iconname="plus"></app-svg-icon>
                                  <p *ngIf="sub.product_selected_quentity > 0">{{sub.product_selected_quentity}}</p>
                                </a>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="accordian-part mt-3" *ngIf="search.searchmode">
                <div class="search-section top">
                  <div class="search-top">
                    <div class="row">
                      <div class="col-lg-9">
                        <h5>Search result for {{search.serchtxt}}</h5>
                      </div>
                      <div class="col-lg-3 svg-search" (click)="resetsearch()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                          <path
                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                        </svg>
                      </div>

                    </div>

                  </div>
                  <ng-container *ngFor="let srchproduct of search.searchresult">
                    <div class="search-section" *ngIf="srchproduct.has_subproduct=='yes'">
                      <div class="panel mt-3">
                        <h5>{{srchproduct.product_name}}</h5>
                        <div class="row product-details-list" *ngFor="let searchsub of srchproduct.sub_product">
                          <div class="col-lg-10">
                            <div class="name-div">
                              <h6>{{searchsub.product_name}}</h6>
                              <h6><span>&pound;{{searchsub.product_price}}</span></h6>
                            </div>
                          </div>
                          <div class="col-lg-2" *ngIf="searchsub.has_option=='yes'">
                            <div class="button-div search-btn">
                              <a href="javascript:void(0)" (click)="openOptionPop(srchproduct)">
                                <app-svg-icon iconname="plus"></app-svg-icon>
                                <p *ngIf="searchsub.product_selected_quentity > 0">{{searchsub.product_selected_quentity}}
                                </p>
                              </a>
                            </div>
                          </div>
                          <div class="col-2" *ngIf="searchsub.has_option=='no'">
                            <div class="button-div search-btn">
                              <a href="javascript:void(0)" (click)="addtocart(searchsub, 's', srchproduct)">
                                <app-svg-icon iconname="plus"></app-svg-icon>
                                <p *ngIf="searchsub.product_selected_quentity > 0">{{searchsub.product_selected_quentity}}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel mt-3" *ngIf="srchproduct.has_subproduct=='no'">
                      <div class="search-section">
                        <div class="row product-details-list search-panel">
                          <div class="col-lg-10">
                            <div class="name-div ">
                              <h6>{{srchproduct.product_name}}</h6>
                              <h6><span>&pound;{{srchproduct.product_price}}</span></h6>
                            </div>
                          </div>
                          <div class="col-2" *ngIf="srchproduct.has_option=='yes'">
                            <div class="button-div search-btn">
                              <a href="javascript:void(0)" (click)="openOptionPop(srchproduct)" class="add-search">
                                <app-svg-icon iconname="plus"></app-svg-icon>
                                <p *ngIf="srchproduct.product_selected_quentity > 0">
                                  {{srchproduct.product_selected_quentity}}</p>
                              </a>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="button-div search-btn" *ngIf="srchproduct.has_option=='no'">
                              <a href="javascript:void(0)" (click)="addtocart(srchproduct, 'm', null)" class="add-search">
                                <app-svg-icon iconname="plus"></app-svg-icon>
                                <p *ngIf="srchproduct.product_selected_quentity > 0">
                                  {{srchproduct.product_selected_quentity}}</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- menu container -->

          <!-- review container -->
          <div class="tab-pane review-section fade" id="review_div" role="tabpanel" aria-labelledby="review-tab">
            <div class="review">
              <div class="review-none">
                <p>No review found for Aladdin Balti Coventry</p>
              </div>


              <p *ngIf=" reviewEmptyMsg!=''">{{reviewEmptyMsg}}</p>
              <p>
                <a class="viewall" routerLink="/information/feedback/{{slug}}"
                  style="margin-bottom:30px;color:#fff;cursor:pointer;">
                  Write Review
                </a>
              </p>
              <div class="rating" *ngIf="!reviewMinLoader && reviewEmptyMsg==''">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div class="overall">
                      <h3>Overall</h3>
                      <h2>{{overall_rating}}/5</h2>
                      <app-star-rating [rating]="overall_rating"></app-star-rating>
                      <small
                        style="color:#fff;margin-top:10px;letter-spacing:0.2em;font-weight:700;">{{overall_rating_text}}</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="diff">
                      <p>Quality:</p>
                      <app-star-rating [rating]="avg_noise_rating"></app-star-rating>
                    </div>
                    <div class="diff">
                      <p>Service:</p>
                      <app-star-rating [rating]="avg_service_rating"></app-star-rating>
                    </div>
                    <div class="diff">
                      <p>Value for money:</p>
                      <app-star-rating [rating]="avg_valueformoney_rating"></app-star-rating>
                    </div>
                  </div>
                </div>
                <p><small>Get detailed reviews about <strong>{{bs_name}}</strong> here. All reviews have been written by
                    BookYup users
                    who have already booked and eaten at <strong>{{bs_name}}</strong>. We try to remain transparent by
                    providing you real
                    and true customer's reviews. Find out what customers say about <strong>{{bs_name}}</strong>.</small>
                </p>
                <p>
                  <small>
                    <ng-container *ngIf="total_review_no>1">
                      <strong>{{total_review_no}}</strong> reviews of <strong>{{bs_name}}</strong>.
                    </ng-container>
                    <ng-container *ngIf="1>=total_review_no">
                      <strong>{{total_review_no}}</strong> review of <strong>{{bs_name}}</strong>.
                    </ng-container>
                  </small>
                </p>
              </div>
              <div class="reviews">
                <div *ngFor="let review of user_review">
                  <div class="review">
                    <div class="d-flex justify-content-between">
                      <h5>
                        {{review.name}}
                        <small>{{review.review_time}}</small>
                      </h5>
                      <app-star-rating [rating]="review.user_avg_rating"></app-star-rating>
                    </div>
                    <q *ngIf="review.review_text!=''">{{review.review_text}}</q>
                  </div>
                </div>
                <img src="assets/images/loader.gif" *ngIf="reviewMinLoader"
                  style="width:100px;display:table;margin:0 auto" />
                <a class="viewall" (click)="loadMoreReview()" *ngIf='!reviewMinLoader && more_review_status'
                  style="margin-bottom:30px;color:#fff;cursor:pointer;">
                  Load More
                </a>
              </div>
            </div>
          </div>
          <!-- review container -->


          <!-- info container -->
          <div class="tab-pane info-details fade" id="info_div" role="tabpanel" aria-labelledby="info-tab">
            <strong *ngIf="info_data.bs_details!=''">About {{info_data.bs_name}}</strong>
            <p *ngIf="info_data.bs_details!=''">{{info_data.bs_details}}</p>
            <div class="info-section">
              <h6>Business Opening Times</h6>
              <table>
                <tbody>
                  <tr *ngFor="let days of info_data.bs_opening_schedule">
                    <td>{{days.day}}</td>
                    <td>{{days.opening_time_schedule_lable}}</td>
                  </tr>
                </tbody>
              </table>
              <h6>Delivery postcode sectors</h6>
              <div class="postcode-sec">
                <ul>
                  <li *ngFor="let postcodes of info_data.bs_delivery_sectors">{{postcodes}}</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- info container -->

          <!-- booking container -->
          <div class="tab-pane book-a-table fade" id="book_div" role="tabpanel" aria-labelledby="book-tab">
            <div class="book-section">
              <h6>Booking Form</h6>
              <p>{{bookingdescription}}</p>

              <form class="row g-3" [formGroup]="bookingForm" #bookingMain="ngForm" (ngSubmit)="booking_call()" id="bookingrequestform" name="bookingrequestform">
                <div class="col-lg-6">
                  <label for="bookingDate" class="form-label">Preferred date <span>*</span></label>
                  <input type="text" class="form-control" id="bookingDate" [(ngModel)]="booking_payload.date" placeholder="Booking Date" formControlName="date" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" (dateSelect)="onBookingDateSelect($event)" autocomplete="off">
                  <div class="error" *ngIf="bookingValidator['date'].errors !=null && bookingValidator['date'].errors['required'] && bookingValidator['date'].touched">Preferred date required</div>
                </div>
                <div class="col-lg-6">
                  <label for="select-occasion" class="form-label">Occasion <span>*</span></label>
                  <select id="select-occasion" class="form-select" [(ngModel)]="booking_payload.occasion" formControlName="occasion" placeholder="Occasion">
                    <option value="">Select Occasion</option>
                    <option value="Birthday">Birthday</option>
                    <option value="Anniversary">Anniversary</option>
                    <option value="Family Reunion">Family Reunion</option>
                    <option value="Friends Party">Friends Party</option>
                    <option value="Special Occasion">Special Occasion</option>
                    <option value="Business Related">Business Related</option>
                    <option value="Others">Others</option>
                  </select>
                  <div class="error" *ngIf="bookingValidator['occasion'].errors !=null && bookingValidator['occasion'].errors['required'] && bookingValidator['occasion'].touched">Occasion required</div>
                </div>
                <div class="col-lg-6" *ngIf="timesloarray.length>0">
                  <label for="select-time" class="form-label">Preferred Time <span>*</span></label>
                  <select id="select-time" class="form-select" [(ngModel)]="booking_payload.time" formControlName="time" placeholder="Preferred Time">
                    <option value="">Select Time</option>
                    <ng-container *ngFor="let timeslotintv of timesloarray; let k=index">
                      <option value="{{timeslotintv}}">{{timeslotintv}}</option>
                    </ng-container>
                  </select>
                  <div class="error" *ngIf="bookingValidator['time'].errors !=null && bookingValidator['time'].errors['required'] && bookingValidator['time'].touched">Preferred time slot required</div>
                </div>
                <div class="col-lg-6" *ngIf="timesloarray.length>0">
                  <label for="select-guest" class="form-label">Preferred Guests <span>*</span></label>
                  <select id="select-guest" class="form-select" [(ngModel)]="booking_payload.guest" formControlName="guest" placeholder="Guests" (change)="calculate_booking_amount()">
                    <option>Select</option>
                    <ng-container *ngFor="let itms of [].constructor(max_person); let ik = index">
                      <option value="{{ik+1}}" *ngIf="(ik+1)>=min_person">{{ik+1}}</option>
                    </ng-container>
                  </select>
                  <div class="error" *ngIf="bookingValidator['guest'].errors !=null && bookingValidator['guest'].errors['required'] && bookingValidator['guest'].touched">Preferred guest number required</div>
                </div>
                <div class="col-md-6">
                  <label for="bookingName" class="form-label">Name <span>*</span></label>
                  <input type="text" class="form-control" name="bookingName" id="bookingName" formControlName="name" [(ngModel)]="booking_payload.name">
                  <div class="error" *ngIf="bookingValidator['name'].errors !=null && bookingValidator['name'].errors['required'] && bookingValidator['name'].touched">Name required</div>
                </div>
                <div class="col-md-6">
                  <label for="bookingEmail" class="form-label">Email <span>*</span></label>
                  <input type="email" class="form-control" name="bookingEmail" id="bookingEmail" formControlName="email" [(ngModel)]="booking_payload.email">
                  <div class="error" *ngIf="bookingValidator['email'].errors !=null && bookingValidator['email'].errors['required'] && bookingValidator['email'].touched">Email required</div>
                  <div class="error" *ngIf="bookingValidator['email'].errors !=null && bookingValidator['email'].errors['email'] && bookingValidator['email'].touched">Please enter valid email</div>
                </div>
                <div class="col-md-6">
                  <label for="bookingMobile" class="form-label">Mobile Number <span>*</span></label>
                  <input type="text" class="form-control" name="bookingMobile" id="bookingMobile" formControlName="phone" [(ngModel)]="booking_payload.phone">
                  <div class="error" *ngIf="bookingValidator['phone'].errors !=null && bookingValidator['phone'].errors['required'] && bookingValidator['phone'].touched">Mobile number required</div>
                  <div class="error" *ngIf="bookingValidator['phone'].errors !=null && bookingValidator['phone'].errors['pattern'] && bookingValidator['phone'].touched">Mobile number must have 11 digit and a leading 0</div>
                </div>
                <div class="col-md-6">
                  <label for="postcode" class="form-label">Postcode <span>*</span></label>
                  <input type="text" class="form-control" id="postcode" formControlName="pincode" [(ngModel)]="booking_payload.pincode">
                  <div class="error" *ngIf="bookingValidator['pincode'].errors !=null && bookingValidator['pincode'].errors['required'] && bookingValidator['pincode'].touched">Postcode required</div>
                  <div class="error" *ngIf="bookingValidator['pincode'].errors !=null && bookingValidator['pincode'].errors['minlength'] && bookingValidator['pincode'].errors['maxlength'] && bookingValidator['pincode'].touched">Please enter valid postcode</div>
                </div>
                <div class="col-md-12">
                  <label for="bookingMessage" class="form-label">Message <span>*</span></label>
                  <textarea rows="3" class="form-control" name="bookingMessage" id="bookingMessage" formControlName="message" [(ngModel)]="booking_payload.message"></textarea>
                  <div class="error" *ngIf="bookingValidator['message'].errors !=null && bookingValidator['message'].errors['required'] && bookingValidator['message'].touched">Message required</div>
                </div>
              </form>
            </div>
          </div>
          <!-- booking container -->

        </div>

      </div>
      <!-- menu right cart start -->
      <ng-container *ngIf="menuLoader">
        <div class="col-lg-4">
          <app-loader loadername='menu-right-cartlist'></app-loader>
        </div>
      </ng-container>
      <ng-container *ngIf="!menuLoader">
        <div id="delivery-holder" class="col-lg-4 menu-cash-total">
          <ng-container *ngIf="selected_tab=='menu'">
            <div class="delivery-collection holder">
              <div class="note-div">
                <div class="svg-div">
                  <app-svg-icon iconname="info-icon-i-yellow"></app-svg-icon>
                </div>
                <div class="text-div">
                  <ng-container class="status closed" *ngIf="pickuppossible == false && delpossible == false">
                    <p *ngIf="buisnessdata.info.bs_next_open != null">{{buisnessdata.info.bs_next_open}}</p>
                  </ng-container>
                  <ng-container *ngIf="pickupmsg == 'Preorder Only' || deliverymsg =='Preorder Only'">
                    <p>We are taking preorders for
                      <span *ngIf="pickupmsg == 'Preorder Only' && pickuppossible">collection</span>
                      <span
                        *ngIf="pickupmsg == 'Preorder Only' && deliverymsg =='Preorder Only' && delpossible && pickuppossible">
                        and </span>
                      <span *ngIf="deliverymsg == 'Preorder Only' && delpossible">delivery.</span>
                    </p>
                  </ng-container>
                  <ng-container
                    *ngIf="pickupmsg != 'Preorder Only' && pickuppossible== true || deliverymsg !='Preorder Only' && delpossible == true">
                    <p>
                      We are taking orders for
                      <span *ngIf="pickupmsg != 'Preorder Only' && pickuppossible">collection</span>
                      <span
                        *ngIf="pickupmsg != 'Preorder Only' && deliverymsg !='Preorder Only' && delpossible && pickuppossible">
                        and </span>
                      <span *ngIf="deliverymsg !='Preorder Only' && delpossible">delivery</span>
                    </p>
                  </ng-container>
                </div>
              </div>
              <div class="allergies">
                <app-svg-icon iconname="info-icon-i"></app-svg-icon>
                <p>If you or someone you're ordering for has food allergy or intolerance, <a href="javascript:void(0)" (click)="openallergypopup()">click here</a></p>
              </div>
              <div class="switch-toggle">
                <div class="button">
                  <input type="radio" id="business_collection_radio_delivery" name="check-substitution-2"
                    [disabled]="!delpossible" [(ngModel)]="deliverytype" value="delivery" (click)="delmodechange($event)"
                    [ngClass]="deliverytype=='delivery'?'active':''" />
                  <label class="btn btn-default" for="business_collection_radio_delivery">
                    <span class="total-div">
                      <div class="left-div"><app-svg-icon iconname="delivery-car-icon"></app-svg-icon></div>
                      <div class="right-div">
                        <h6>Delivery</h6>
                        <p>{{deliverymsg}}</p>
                      </div>
                    </span>
                  </label>
                </div>
                <div class="button">
                  <input type="radio" id="business_collection_radio_pickup" name="check-substitution-2"
                    [disabled]="!pickuppossible" [(ngModel)]="deliverytype" value="pickup" (change)="pickmodechange()"
                    [ngClass]="deliverytype=='pickup'?'active':''" />
                  <label class="btn btn-default" for="business_collection_radio_pickup">
                    <span class="total-div">
                      <div class="left-div"><app-svg-icon iconname="collection-icon"></app-svg-icon></div>
                      <div class="right-div">
                        <h6>Collection</h6>
                        <p>{{pickupmsg}}</p>
                      </div>
                    </span>
                  </label>
                </div>
              </div>
              <div class="tab-slider--container">
                <div id="tab1" class="tab-slider--body">
                  <div class="tab-first-div">

                  </div>
                </div>
                <div id="tab2" class="tab-slider--body">
                  <div class="tab-first-div second-content" *ngIf="cartitems.length > 0">
                    <table class="eliment-content">
                      <tbody>
                        <tr>
                          <td class="item-name">items</td>
                          <td class="item-middle"></td>
                          <td class="item-price">price</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="item-product-details">
                      <tbody>
                        <tr *ngFor="let indiv of cartitems; let ind=index;">
                          <td class="plus-minus">
                            <a href="javascript:void(0)" (click)="remove(indiv.product_id, ind)">
                              <app-svg-icon iconname="circle-minus"></app-svg-icon>
                            </a>
                            <h5>{{$any(indiv.product_qnty)}}</h5>
                            <a href="javascript:void(0)" (click)="addqty(indiv)">
                              <app-svg-icon iconname="circle-plus"></app-svg-icon>
                            </a>
                          </td>
                          <td class="item-name">
                            <h5>{{indiv.product_name}} <ng-container
                                *ngIf="indiv.main_pro_id !=''">{{indiv.main_pro_name}}</ng-container></h5>
                            <ng-container *ngIf="indiv.extras !=''">
                              <ng-container *ngFor="let ex of indiv.extras">
                                <ng-container *ngIf="ex.show_type=='radio'">
                                  <ng-container *ngFor="let exoption of ex.option_list">
                                    <p>{{ex.option_text_name}} {{exoption.name}}</p>
                                    <div *ngFor="let condexoption of exoption.condoptiondetails">
                                      <span *ngIf="condexoption.show_type=='radio'"
                                        class="pl-3 bullet">{{condexoption.choice_name_end_user}}</span>
                                      <span *ngIf="condexoption.show_type=='checked'" class="pl-3">+ 1 x
                                        {{condexoption.choice_name_end_user}}</span>
                                    </div>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="ex.show_type=='checked'">
                                  <ng-container *ngFor="let exoption of ex.option_list">
                                    <p>{{ex.option_text_name}}</p>
                                    <div *ngFor="let condexoption of exoption.condoptiondetails">
                                      <span *ngIf="condexoption.show_type=='radio'"
                                        class="pl-3 bullet">{{condexoption.choice_name_end_user}}</span>
                                      <span *ngIf="condexoption.show_type=='checked'" class="pl-3">+ 1 x
                                        {{condexoption.choice_name_end_user}}</span>
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </td>
                          <td class="price-tag">
                            <h5>&pound;{{($any(indiv.product_qnty) * $any(indiv.sell_price)).toFixed(2)}}</h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ng-container *ngIf="appliedfreedish!=undefined && appliedfreedish.length>0">

                      <table class="free-dish-top">
                        <tbody>
                          <tr>
                            <td class="free-title">
                              <h6>Freebies</h6>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ng-container *ngFor="let dishitem of appliedfreedish">

                        <div class="free-total-div">
                          <table class="free-dish-items">
                            <tbody>
                              <ng-container *ngFor="let freedish of dishitem.freedish_item">
                                <ng-container *ngIf="freedish.selected!=undefined && freedish.selected=='yes'">
                                  <tr>
                                    <td class="items"><ng-container
                                        *ngIf="dishitem.qnty!=undefined && $any(dishitem.qnty)>0 else singlefreeqty">{{dishitem.qnty}}</ng-container><ng-template
                                        #singlefreeqty>1</ng-template> × <span>{{freedish.fd_name}}</span></td>
                                    <td class="price">&pound; {{($any(dishitem.qnty | number:'1.2-2')*
                                      $any(freedish.fd_price | number:'1.2-2')) | number:'1.2-2'}}</td>
                                  </tr>
                                </ng-container>
                              </ng-container>
                              <tr *ngIf="isCustomizableFreedish=='yes' && dishitem.is_selectable>0">
                                <td class="edit-btn">
                                  <a type="button" data-bs-toggle="modal" data-bs-target="#exampleModal5"
                                    (click)="open_freedish_option(dishitem.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#228f00"
                                      class="bi bi-pencil-square" viewBox="0 0 16 16">
                                      <path
                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                      <path fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                    </svg>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </ng-container>

                    </ng-container>
                    <table class="Sub-total-sec">
                      <tbody>
                        <tr>
                          <td class="name" colspan="2">SubTotal</td>
                          <td class="price">&pound;{{totalpayble | number:'1.2-2'}}</td>
                        </tr>
                        <tr>
                          <td class="name" colspan="2">Service Charge</td>
                          <td class="price">&pound;{{servicecharge | number:'1.2-2'}}</td>
                        </tr>
                        <tr>
                          <td class="name" colspan="2">Delivery Charge
                            <br>
                            <ng-container
                              *ngIf="delivery_charge_waiver!=undefined && delivery_charge_waiver!=null && delivery_charge_waiver>0">
                              <ng-container *ngIf="zone_delivery_charg>0 else freeOrderDeliveryMsg">
                                <div class="del-waiver-msg-add-more">(Add More For Free Delivery
                                  <span>&pound;{{delivery_charge_waiver - totalpayble | number:'1.2-2'}}</span>.)</div>
                              </ng-container>
                              <ng-template #freeOrderDeliveryMsg>
                                <div class="del-waiver-msg-free">(Free Delivery Applied.)</div>
                              </ng-template>
                            </ng-container>
                          </td>
                          <td class="price">&pound;{{zone_delivery_charg | number:'1.2-2'}}</td>
                        </tr>
                        <tr>
                          <td class="name" colspan="2">Baggage charge</td>
                          <td class="price">&pound;{{baggagecharge | number:'1.2-2'}}</td>
                        </tr>
                        <tr>
                          <td class="name" colspan="2">10% Discount</td>
                          <td class="price">-&pound;0.99</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="total-price">
                      <tbody>
                        <tr>
                          <td class="total-p">
                            Total
                          </td>
                          <td class="price">
                            &pound;{{((totalpayble + additionalchageamnt + servicecharge + baggagecharge) -
                            total_discount).toFixed(2)}}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div class="input-sec">
                      <form>
                        <div class="mb-3">
                          <input type="text" class="form-control" placeholder="Discount code" id="exampleInputEmail1"
                            aria-describedby="emailHelp">
                          <a href="#">
                            APPLY
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-chevron-right" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg>
                          </a>
                        </div>
                      </form>
                    </div>
                    <div class="anchor-tag">
                      <button data-bs-toggle="modal" data-bs-target="#exampleModal10"
                        [disabled]="totalpayble < minorderamnt"
                        *ngIf="cartitems.length > 0 && (delpossible || pickuppossible)" (click)="gotocheckout()">
                        Go to checkout
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selected_tab=='book' && ((total_booking_person_price+every_booking_price + total_booking_service_charge_price) > 0 || ((booking_payload.paymode=='cod' || booking_payment_accept_over_guest_number>0) && booking_payload.date!='' && booking_payload.guest!='' && total_booking_person_price+every_booking_price + total_booking_service_charge_price == 0))">
            <div class="delivery-collection">
              <div class="tab-slider--container">
                <div id="tab2" class="tab-slider--body">

                  <div class="tab-first-div second-content">

                    <table class="booking-side-panel">
                      <ng-container *ngIf="(total_booking_person_price+every_booking_price+total_booking_service_charge_price)>0; else desktopBookingCartTbody">
                        <tbody>
                          <tr>
                            <td class="book-panel-left">
                              <h6>PARTICULARS</h6>
                            </td>
                            <td class="book-panel-right">
                              <h6>PRICE</h6>
                            </td>
                          </tr>
                          <tr *ngIf="every_booking_price>0">
                            <td class="book-panel-left">
                              <p>Booking Deposit</p>
                            </td>
                            <td class="book-panel-right">
                              <p>&pound;{{ every_booking_price | number : '1.2'}}</p>
                            </td>
                          </tr>
                          <tr *ngIf="every_person_price>0 && total_booking_person_price>0">
                            <td class="book-panel-left">
                              <p>{{ booking_payload.guest }} <ng-container *ngIf="booking_payload.guest=='1'; else codTdBookingGuest">Guest No</ng-container><ng-template #codTdBookingGuest>Guest's No</ng-template> x <span>&pound;{{ every_person_price | number : '1.2' }}</span></p>
                            </td>
                            <td class="book-panel-right">
                              <p>&pound;{{ total_booking_person_price | number : '1.2' }}
                              </p>
                            </td>
                          </tr>
                          <tr *ngIf="(total_booking_person_price+every_booking_price)>0">
                            <td class="book-panel-left">
                              <p>Subtotal</p>
                            </td>
                            <td class="book-panel-right">
                              <p>&pound;{{(total_booking_person_price+every_booking_price) | number:'1.2-2'}}</p>
                            </td>
                          </tr>
                          <tr *ngIf="total_booking_service_charge_price>0">
                            <td class="book-panel-left"><p>Service charge</p></td>
                            <td class="book-panel-right"><p>&pound;{{total_booking_service_charge_price | number:'1.2-2'}}</p>
                            </td>
                          </tr>
                          <tr *ngIf="(total_booking_person_price+every_booking_price + total_booking_service_charge_price)>0">
                            <td class="book-panel-left">
                              <p>Total</p>
                            </td>
                            <td class="book-panel-right">
                              <p>&pound;{{(total_booking_person_price+every_booking_price + total_booking_service_charge_price).toFixed(2)}} </p>
                            </td>
                          </tr>
                          <tr class="total" *ngIf="!((booking_payload.paymode=='cod' || booking_payment_accept_over_guest_number>0) && total_booking_person_price+every_booking_price + total_booking_service_charge_price == 0)">
                            <td colspan="3">
                              <div class="checkout-all">
                                <div class="innerInfo mt-2"><strong>Select Payment Method</strong></div>
                                <div class="checkout bottom">
                                  <div class="switch-toggle left">
                                    <div class="button" *ngFor="let paymodedata of booking_payment_permission">
                                      <input type="radio" id="{{paymodedata.value}}" name="dmode" value="{{paymodedata.value}}" [(ngModel)]="booking_payload.paymode" (change)="changeBookingPaytype(paymodedata.value)" />
                                      <label class="btn btn-default" for="{{paymodedata.value}}">
                                        <span class="total-div mt-2">
                                          <div class="left-div">
                                            <app-svg-icon iconname="paypal" *ngIf="paymodedata.value.toLowerCase()=='paypal'"></app-svg-icon>
                                            <app-svg-icon iconname="cash" *ngIf="paymodedata.value.toLowerCase()=='cod'"></app-svg-icon>
                                            <app-svg-icon iconname="card" *ngIf="paymodedata.value.toLowerCase()!='paypal' && paymodedata.value.toLowerCase()!='cod'"></app-svg-icon>
                                          </div>
                                          <div class="right-div">
                                            <h6>{{ paymodedata.leble | titlecase }}</h6>
                                          </div>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr class="total bookingCartNew" *ngIf="!((booking_payload.paymode=='cod' || booking_payment_accept_over_guest_number>0) && total_booking_person_price+every_booking_price + total_booking_service_charge_price == 0)">
                            <td colspan="3">
                              <form action="#" #booking1="ngForm">
                                <input type="checkbox" name="booking_agree" value="agree" [(ngModel)]="booking_agree_checkbox"> I agree to BookYup's <a routerLink="/information/terms-and-condition" rel="nofollow" target="_blank"><strong>Terms & Conditions</strong></a> and <a routerLink="/information/privacy-policy" rel="nofollow" target="_blank"><strong>Privacy Policy</strong></a>.
                              </form>
                            </td>
                          </tr>
                        </tbody>
                      </ng-container>
                      <ng-template #desktopBookingCartTbody>
                        <tbody>
                          <tr *ngIf="booking_payload.date!=''">
                            <td>Booking Date</td>
                            <td>&nbsp;</td>
                            <td>{{booking_date_label}}</td>
                          </tr>
                          <tr *ngIf="booking_payload.time!=''">
                            <td>Booking Time</td>
                            <td>&nbsp;</td>
                            <td>{{booking_payload.time}}</td>
                          </tr>
                          <tr *ngIf="booking_payload.guest!=''">
                            <td><ng-container *ngIf="booking_payload.guest=='1'; else codTdBookingGuest">Guest No</ng-container><ng-template #codTdBookingGuest>Guest's No</ng-template></td>
                            <td>&nbsp;</td>
                            <td>{{booking_payload.guest}} </td>
                          </tr>
                          <tr *ngIf="booking_payload.name!=''">
                            <td>Name</td>
                            <td>&nbsp;</td>
                            <td>{{booking_payload.name}}</td>
                          </tr>
                          <tr *ngIf="booking_payload.email!=''">
                            <td>Email</td>
                            <td>&nbsp;</td>
                            <td>{{booking_payload.email}}</td>
                          </tr>
                          <tr *ngIf="booking_payload.phone!=''">
                            <td>Mobile</td>
                            <td>&nbsp;</td>
                            <td>{{booking_payload.phone}}</td>
                          </tr>
                          <tr *ngIf="booking_payload.occasion!=''">
                            <td>Occasion</td>
                            <td>&nbsp;</td>
                            <td>{{booking_payload.occasion}}</td>
                          </tr>
                        </tbody>
                      </ng-template>
                    </table>

                    <div class="anchor-tag">
                      <ng-container *ngIf="((booking_payload.paymode=='cod' || booking_payment_accept_over_guest_number>0) && total_booking_person_price+every_booking_price + total_booking_service_charge_price == 0); else desktopBookingCartSubmitBtn">
                        <input type="submit" name="" class="chkbtn" value="Book Now" (click)="mobBookingSubmit()">
                      </ng-container>
                      <ng-template #desktopBookingCartSubmitBtn>
                        <input type="submit" name="" class="chkbtn" value="Pay & Book Now" (click)="mobBookingSubmit()" [disabled]="!(booking_payload.paymode!='' && booking_agree_checkbox)">
                        <div class="alert alert-danger alert-booking-term-condition-check" *ngIf="booking_payload.paymode=='' && !booking_agree_checkbox">Please agree our Terms & Conditions and choose a valid payment method.</div>
                        <div class="alert alert-danger alert-booking-term-condition-check" *ngIf="booking_payload.paymode=='' && booking_agree_checkbox">Please select a payment method.</div>
                        <div class="alert alert-danger alert-booking-term-condition-check" *ngIf="booking_payload.paymode!='' && !booking_agree_checkbox">Please agree our Terms & Conditions.</div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- menu right cart end -->
      <!-- // floating button start here -->
      <div class="floating-button-menu" [ngClass]="menuCatagoryShow?'menu-on':'menu-off'">
        <div class="floating-button-menu-links">
          <div class="cross-btn"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm79 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
            </svg></div>
          <ng-container *ngFor="let cat of category">
            <a href="javascript:void(0)" (click)="openOneMenuCategory(cat.menucategory_id)">
              <p>{{cat.menucategory_name}}</p>
            </a>
          </ng-container>
        </div>
        <div class="floating-button-menu-label" (click)="toggleMenuCategory()">
          <h5><span><img src="../../../assets/images/menu/cooking.png" alt=""></span>Menu</h5>
        </div>
      </div>
      <!-- // floating button end here -->

    </div>
  </div>
</section>
<ng-template #deliverymodal let-modal>
  <!-- <form #delf="ngForm" (ngSubmit)="selectDeliveryType(delf.form.valid)" class="collection-delivery-options" name="servicetypeform"> -->
    <div class="modal-header">
      <h1 class="modal-title fs-5" id="exampleModalLabel">Choose Service Type</h1>
      <button type="button" class="close model" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body myform">
      <div class="fldarea custom-checkbox2">

        <div class="switch-toggle add-business">
          <div class="button">
            <div class="row">
              <div class="col-lg-6">
                <div class="add-business">
                  <input type="radio" id="business_collection_radio_deliverymodal" name="service_type1" [(ngModel)]="deliverytypeoption" value="delivery" [disabled]="!delpossible" />
                  <label class="btn-default" for="business_collection_radio_deliverymodal">
                    <span class="total-div">
                      <div class="right-div">
                        <h6>Delivery</h6>
                      </div>
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="add-business">
                  <input type="radio" id="business_collection_radio_pickupmodal2" name="service_type1" [(ngModel)]="deliverytypeoption" value="pickup" [disabled]="!pickuppossible" />
                  <label class="btn-default" for="business_collection_radio_pickupmodal2">
                    <span class="total-div">
                      <div class="right-div">
                        <h6>Collection</h6>
                      </div>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn close btn-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="button" class="btn save btn-primary" (click)="selectDeliveryType()" value="Save Changes">Save Changes</button>
    </div>
  <!-- </form> -->
</ng-template>

<ng-template #zipcodemodal let-modal>
  <form class="myform postcode-modal" #f="ngForm" (ngSubmit)="checkDelivery(f.form.valid)">
    <div class="modal-header">
      <h3 class="modal-title fs-5" id="exampleModalLabel">Enter Postcode</h3>
      <button type="button" class="close" id="close-top" aria-label="Close " (click)="modal.dismiss('Cross click');">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="filter-section">
        <div class="mb-3">
          <input type="email" class="form-control" placeholder="Enter postcode here" name="pincode" #pin="ngModel"
            [(ngModel)]="selectedZip" required>
          <div class="alert alert-danger" *ngIf="(pin.invalid && pin.touched) || (pin.invalid && f.submitted)">
            Please Enter Postcode
          </div>
          <div class="popup errpr message" *ngIf="validzipcodetext !=''">{{validzipcodetext}}</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn close btn-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button type="submit" class="btn save btn-primary" value="Save Changes">Save Changes</button>
    </div>
  </form>
</ng-template>

<ng-template #extrapopup let-modal>
  <div class="modal fade modal-filter show" id="exampleModal14" style="display: block; padding-left: 0px;">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content menucard-modal-details">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Menu Card</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
          <h3>Chichen Curry</h3>
          <p class="text-center">Green peppers, sweetcorn, jalapenos, onions &amp; mushrooms</p>
          <img class="modal-body-img" src="assets/images/menu/banner.jpg" alt="">
          <div class="total-item-menu">
            <div class="head-item">
              <h4>Choose any 1</h4>
              <div class="required">
                <p class="active">Required</p>
              </div>
            </div>
            <div class="menucard-modal">
              <form class="row">

                <div class="form-check col-lg-12">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">Check me out</label>
                </div>
                <div class="total-item-menu">
                  <div class="head-item">
                    <h4>Choose any 1</h4>
                  </div>
                  <div class="menucard-modal">


                    <div class="form-check col-lg-12">
                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                      <label class="form-check-label" for="flexRadioDefault1">
                        Default radio
                      </label>
                    </div>


                  </div>
                </div>
              </form>


            </div>
          </div>

          <div class="total-item-menu">
            <div class="head-item">
              <p class="text-left mb-0">this the best option all the time</p>
              <h4>Choose any 1</h4>

            </div>
            <div class="menucard-modal">
              <form class="row">

                <div class="form-check col-lg-12">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                  <label class="form-check-label" for="flexRadioDefault1">
                    SET MEALS FOR 4 PEOPLE
                    <p class="m-0">(Indian Grill Starter, Onion Bhaji, Sheek Kebab starter, Chicken Tikka Starter,
                      Chicken Dansak, Chicken Tikka Masala, Lamb Jallfrazi, King Prawn Curry, Bombay potato, Mushroom
                      Bhaji, Mixed Vegetable Curry, Dall Masala, Pilau Rice (3 portions), Boiled Rice, 3 Naans, 4
                      Papadoms.)</p>
                  </label>
                </div>

                <div class="form-check col-lg-12">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                  <label class="form-check-label" for="flexRadioDefault1">
                    SET MEALS FOR 2 PEOPLE
                    <p class="m-0">(Indian Grill Starter, Onion Bhaji, Sheek Kebab starter, Chicken Tikka Starter,
                      Chicken Dansak, Chicken Tikka Masala, Mixed Vegetable Curry, Dall Masala, Pilau Rice (3 portions),
                      Boiled Rice, 3 Naans, 4 Papadoms.)</p>
                  </label>
                </div>

                <div class="form-check col-lg-12">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Default radio
                  </label>
                </div>

                <div class="form-check col-lg-12">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Default radio
                  </label>
                </div>

              </form>
            </div>
          </div>

          <div class="total-item-menu">
            <div class="head-item">
              <h4>Choose any 1</h4>
              <div class="required">
                <p>Required</p>
              </div>
            </div>
            <div class="menucard-modal">
              <form class="row">

                <div class="form-check col-lg-12">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Default radio
                  </label>
                </div>

                <div class="form-check col-lg-12">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Default radio
                  </label>
                </div>

                <div class="form-check col-lg-12">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Default radio
                  </label>
                </div>

                <div class="form-check col-lg-12">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Default radio
                  </label>
                </div>

              </form>
            </div>
          </div>

          <div class="total-item-menu">
            <div class="head-item">
              <h4>Choose any 1</h4>
            </div>
            <div class="menucard-modal">
              <form class="row">

                <div class="form-check col-lg-12">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">Check me out</label>
                </div>

                <div class="form-check col-lg-12">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">
                    Check me out
                    <p class="m-0">(Indian Grill Starter, Onion Bhaji, Sheek Kebab starter, Chicken Tikka Starter,
                      Chicken Dansak, Chicken Tikka Masala, Lamb Jallfrazi, King Prawn Curry, Bombay potato, Mushroom
                      Bhaji.)</p>
                  </label>
                </div>

                <div class="form-check col-lg-12">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">
                    SET MEALS FOR 4 PEOPLE in dinner
                    <p class="m-0">(Indian Grill Starter, Onion Bhaji, Sheek Kebab starter, Chicken Tikka Starter,
                      Chicken Dansak, Chicken Tikka Masala, Lamb Jallfrazi, King Prawn Curry, Bombay potato, Mushroom
                      Bhaji, Mixed Vegetable Curry, Dall Masala, Pilau Rice (3 portions), Boiled Rice, 3 Naans, 4
                      Papadoms.)</p>
                  </label>
                </div>

                <div class="form-check col-lg-12">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">Check me out</label>
                </div>

              </form>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <div class="total-check-foot">
            <div class="button-div-all">
              <a href="#">
                <app-svg-icon iconname="option-circle-minus"></app-svg-icon>
              </a>
              <h5>2</h5>
              <a href="#">
                <app-svg-icon iconname="option-circle-plus"></app-svg-icon>
              </a>
            </div>
            <div class="anchor-div">
              <a href="#">Checkout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #freedishoptionpopup let-modal>
  <div class="menuoptions-modal freedish">
    <div class="modal-header green">
      <h3 class="modal-title d-flex justify-content-start"><span *ngIf="freeDishAutoPopupOpenArr.length==1"><h1 class="d-flex justify-content-start">You have unlock a freedish</h1></span><span
          *ngIf="freeDishAutoPopupOpenArr.length>1">You have unlock another freedish</span></h3>
      <button type="button" class="close model" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body myform menuoptions">
      <div *ngFor="let fd1 of appliedfreedish; let checkInd = index;">
        <ng-container *ngIf="fd1.is_selectable>=0 && fd1.id==showFreeDishOptionId">
          <ng-container *ngIf="fd1.welcome_text!=undefined && fd1.welcome_text!=null && fd1.welcome_text!=''">

            <div class="selection-title">
              <p>{{fd1.welcome_text}}</p>
            </div>
          </ng-container>

          <div class="title" [ngClass]="checkInd==0?'mt-0':'mt-0'">
            <h5>Choose Your Freedish</h5>
            <span *ngIf="fd1.is_selectable==0">This is not editable</span>
            <span *ngIf="fd1.is_selectable==1" class="mb-2">Choose any one </span>
            <span *ngIf="fd1.is_selectable>1">Choose any {{fd1.is_selectable}} items</span>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-md-6 mb" *ngFor="let fditem1 of fd1.freedish_item; let itemIds = index;">
              <div class="item model-freedish" *ngIf="fd1.is_selectable>1">
                <div class="fldarea checkbox d-flex gap-3">
                  <input type="checkbox" [checked]="fditem1.selected=='yes'?'checked':''" class="radio-input"
                    name="fdop_{{fd1.id}}_{{fditem1.fd_id}}" id="fdop_{{checkInd}}_{{itemIds}}"
                    value="{{fditem1.fd_name}}" (change)="updateFreedishOption($event,fd1,fditem1)"
                    [disabled]="fditem1.disable=='yes'" />
                  <label for="fdop_{{checkInd}}_{{itemIds}}" style="display:block;">
                    <div class="check"></div>
                    {{fditem1.fd_name}}
                  </label>
                </div>
              </div>
              <div class="item" *ngIf="fd1.is_selectable==1">
                <div class="fldarea checkbox d-flex listing">
                  <input type="radio" [checked]="fditem1.selected=='yes'?'checked':''" name="fdrd_{{fd1.id}}"
                    value="{{fditem1.fd_name}}" (change)="updateFreedishOption($event,fd1,fditem1)"
                    id="fdrd_{{fd1.id}}_{{fditem1.fd_id}}" />
                  <label for="fdrd_{{fd1.id}}_{{fditem1.fd_id}}">
                    <div class="radio"></div>
                    {{fditem1.fd_name}}
                  </label>
                </div>
              </div>
              <div class="item" *ngIf="fd1.is_selectable==0">
                <div class="fldarea checkbox">
                  <input type="checkbox" name="" id="" value="" readonly="readonly"
                    [checked]="fditem1.selected=='yes'?'checked':''" [disabled]="fditem1.disable=='yes'" />
                  <label for="fdop_{{checkInd}}_{{itemIds}}" style="display:block;">
                    <div class="check"></div>
                    {{fditem1.fd_name}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <div></div>
      <div class="btn btn-dark" (click)="modal.dismiss('Cross click')">OK</div>
    </div>
  </div>
</ng-template>

<ng-template #closeRest let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Sorry!!</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    The restaurant is closed at the moment. You'll be able to preorder before the next opening hours. Please try again
    later.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
<!-- menu page end here -->






<!-- footer start here -->
<app-footer />
<!-- footer end here -->