<ng-container *ngIf="!showdloader">
  <div class="row justify-content-center">
    <div class="col-lg-6">
      <div class="otp_verify_area">
        <div class="top_form_content">
          <h2>Verify your phone number</h2>
        </div>
        <div class="form_container">
          <div size="lg" class="modal-body myform">
            <div class="row align-items-center">
              <div class="col-lg-12">
                <div class="fldarea">
                  <div class="mobile_edit_info">
                    <form class="otp-verify-form" #cngMobForm="ngForm" (ngSubmit)="changeMobApi(cngMobForm.form.valid)">
                      <div class="input_group">
                        <div class="btn_status">
                          <label for=""> Mobile Number<span class="text-red">*</span></label>
                          <div class="btn_group">
                            <div class="btninfomobile" *ngIf="!changeMobileBtnClick">
                              <i class="las la-edit"></i> <input type="button" name="Edit" value="Edit" class="btn btn_ex" (click)="changeMobileBtn()">
                            </div>
                            <div class="btninfomobile" *ngIf="changeMobileBtnClick && !mobileNumberChanged">
                              <i class="las la-window-close"></i> <input type="button" name="Cancel" value="Cancel" class="btn btn_ex" (click)="cancelEditMobile()">
                            </div>
                            <div class="btninfomobile" *ngIf="changeMobileBtnClick && mobileNumberChanged">
                              <i class="las la-share-square"></i> <input type="submit" name="submit" value="Send" class="btn btn_ex">
                            </div>
                            <div class="btninfomobile" *ngIf="!changeMobileBtnClick">
                              <i class="las la-sync"></i> <input type="button" name="resend" value="Resend" class="btn btn_ex" (click)="resendMobApi()">
                            </div>
                          </div>
                        </div>
                        <div>
                          <input type="text" inputmode="numeric" id="changeMobileNo" name="changeMobileNo" [(ngModel)]="mobile_new" #cngMob="ngModel" pattern="^0[0-9]\d{9}$" (ngModelChange)="checkMobUpdate()" [ngClass]="!changeMobileBtnClick?'inputDisable':''" [disabled]="!changeMobileBtnClick" required/>
                          <div class="error-text" *ngIf="(cngMob.touched && cngMob.invalid) || (cngMob.invalid && cngMobForm.submitted)">
                            <span *ngIf="cngMob.value ==''">Mobile Number is required</span>
                            <span *ngIf="cngMob.value !=''">
                              <ng-container *ngIf="cngMob!=undefined && cngMob!=null && cngMob.value!=undefined && cngMob.value!=null && cngMob.value.match(' ') else blankSpaceErr">
                                Space is not allowed
                              </ng-container>
                              <ng-template #blankSpaceErr>
                                Mobile number must have 11 digit and a leading 0
                              </ng-template>
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <label>Verification Code</label>
                  <div class="input_otp">
                    <input type="text" inputmode="numeric" name="otpTxt" #otp="ngModel" pattern="^\d{6}$" required [(ngModel)]="mobile_otp" class="form-control ng-untouched ng-pristine ng-invalid">
                    <div class="error-text" *ngIf="(otp.touched && otp.invalid)">
                      <span *ngIf="otp.value ==''">Verification code is required</span>
                      <span *ngIf="otp.value !=''">
                        <ng-container *ngIf="otp!=undefined && otp!=null && otp.value!=undefined && otp.value!=null && otp.value.match(' ') else blankSpaceErr">
                          Space is not allowed
                        </ng-container>
                        <ng-template #blankSpaceErr>
                          Verify number must have 6 digit
                        </ng-template>
                      </span>
                    </div>
                  </div>
                  <label for="" *ngIf="timerM!='00' && timerS!='00'">Your verification code will expire in  <strong>{{timerM}}:{{timerS}}</strong></label>
                  <input type="button" class="btn" name="submit" (click)="verifyOtp(!otp.invalid)" value="Verify">
                </div>
              </div>
            </div>
          </div>
        </div>
        <label class="msg" *ngIf="apiSuccMsg!=''">{{apiSuccMsg}}</label>
        <label class="errMsg" *ngIf="apiErrMsg!=''">{{apiErrMsg}}</label>
      </div>
    </div>
  </div>
</ng-container>
<!-- <ng-template #loader>
  <app-loader></app-loader>
</ng-template> -->